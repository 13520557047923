import type { HighlightResult } from "@/highlight";
import type { Upload } from "@api/schemas";
import { makeAutoObservable, runInAction } from "mobx";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { createContext, useContext, useEffect, useState } from "react";
import type { VariableSizeList } from "react-window";

export class PDFViewerState {
	upload: Upload;
	// this should be read-only except from the virtualized list, which syncs it
	private _currentPageIndex = 0;
	highlightProps: HighlightProps | null = null;
	highlightResult: HighlightResult | null = null;

	pdf: {
		document: PDFDocumentProxy;
		pageDimensions: Map<number, [number, number]>;
		averagePageHeight: number;
	} | null = null;

	// pdf: PDFDocumentProxy | null = null;
	// pageDimensions: Map<number, [number, number]> | null = null;
	// averagePageHeight: number | null = null;

	listRef: VariableSizeList | null = null;

	constructor(upload: Upload, highlightProps: HighlightProps | null) {
		this.upload = upload;
		this.highlightProps = highlightProps;

		makeAutoObservable(this);
	}

	setCurrentPageIndex(index: number) {
		this._currentPageIndex = index;
	}

	get currentPageIndex() {
		return this._currentPageIndex;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const PDFViewerContext = createContext<PDFViewerState>(null as any);

export const usePDFViewerContext = () => {
	const context = useContext(PDFViewerContext);
	if (!context) {
		throw new Error("useViewerContext must be used within a ViewerProvider");
	}
	return context;
};

export type HighlightProps = {
	textToHighlight: {
		textStart: string;
		textEnd?: string;
	};
	pageIndicesToSearch: number[];
};

export const PDFViewerProvider: React.FC<{
	children: React.ReactNode;
	upload: Upload;
	highlight: HighlightProps | null;
	connectToParent: (viewerState: PDFViewerState) => () => () => void;
}> = ({ children, upload, highlight, connectToParent }) => {
	const [viewerState] = useState(() => new PDFViewerState(upload, highlight));

	useEffect(connectToParent(viewerState), []);

	useEffect(
		function propagateHighlightParams() {
			runInAction(() => {
				viewerState.highlightProps = highlight;
			});
		},
		[viewerState, highlight],
	);

	return (
		<PDFViewerContext.Provider value={viewerState}>
			{children}
		</PDFViewerContext.Provider>
	);
};
