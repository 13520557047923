import type { AppState } from "@/contexts/AppContext";
import { createSyncedAction } from "@/contexts/SyncedActions";
import {
	getNewActionInputId,
	getNewActionOutputId,
	getNewActionRunId,
	getNewMessageId,
	getNewStepUserId,
} from "@/idGenerators";
import {
	syncMessageActionRoute,
	syncOpenThreadActionRoute,
} from "@api/fastAPI";
import type {
	ActionInput,
	ActionInputArgsMessage,
	ActionInputArgsOpenThread,
	ActionInputId,
	ActionInputMessage,
	ActionInputOpenThread,
	ActionOutput,
	ActionOutputId,
	ActionOutputMessage,
	ActionOutputOpenThread,
	ActionRun,
	ActionRunMessage,
	ActionRunOpenThread,
	ActionType,
	Message,
	SessionUserId,
	StepAssistantId,
	StepUser,
	StepUserId,
	SyncUserMessageActionRequest,
	SyncUserOpenThreadActionRequest,
} from "@api/schemas";

export function createStepUserLocally(this: AppState, stepUser: StepUser) {
	this.session?.stepsUser.set(stepUser.step_user_id, stepUser);
}

export function createActionInputLocally(
	this: AppState,
	actionInput: ActionInput,
) {
	this.session?.actionInputs.set(actionInput.action_input_id, actionInput);
}

export function createMessageLocally(this: AppState, message: Message) {
	this.session?.messages.set(message.message_id, message);
}

export function createActionOutputLocally(
	this: AppState,
	actionOutput: ActionOutput,
) {
	this.session?.actionOutputs.set(actionOutput.action_output_id, actionOutput);
}

export function createActionRunLocally(this: AppState, actionRun: ActionRun) {
	this.session?.actionRuns.set(actionRun.action_run_id, actionRun);
}

function createNewStepUser({
	sessionUserId,
}: {
	sessionUserId: SessionUserId;
}) {
	const newStep: StepUser = {
		step_user_id: getNewStepUserId(),
		session_user_id: sessionUserId,
		created_at: new Date().toISOString(),
	};
	return newStep;
}

type ActionInputTypeMap = {
	[T in ActionInput as T["action_type"]]: T;
};

function createNewActionInput<T extends ActionType>({
	actionType,
	stepUserId,
	stepAssistantId,
	args,
}: {
	actionType: T;
	stepUserId: StepUserId | null;
	stepAssistantId: StepAssistantId | null;
	args: ActionInputTypeMap[T]["args"];
}): ActionInputTypeMap[T] {
	return {
		action_input_id: getNewActionInputId(),
		action_type: actionType,
		created_at: new Date().toISOString(),
		step_user_id: stepUserId,
		step_assistant_id: stepAssistantId,
		args,
	} as ActionInputTypeMap[T];
}

type ActionOutputTypeMap = {
	[T in ActionOutput as T["action_type"]]: T;
};

function createNewActionOutput<T extends ActionType>({
	actionType,
	data,
}: {
	actionType: T;
	data: ActionOutputTypeMap[T]["data"];
}): ActionOutputTypeMap[T] {
	return {
		action_output_id: getNewActionOutputId(),
		action_type: actionType,
		created_at: new Date().toISOString(),
		data,
	} as ActionOutputTypeMap[T];
}

type ActionRunTypeMap = {
	[T in ActionRun as T["action_type"]]: T;
};

function createNewActionRun<T extends ActionType>({
	actionType,
	actionInputId,
	actionOutputId,
}: {
	actionType: T;
	actionInputId: ActionInputId;
	actionOutputId: ActionOutputId;
}): ActionRunTypeMap[T] {
	return {
		action_run_id: getNewActionRunId(),
		action_type: actionType,
		created_at: new Date().toISOString(),
		action_input_id: actionInputId,
		action_output_id: actionOutputId,
		error: null,
	} as ActionRunTypeMap[T];
}

/**
 * Through the client, the User generates ActionInputs. These functions wrap
 * them in Steps.
 */
export const executeActionMessage = createSyncedAction<
	AppState,
	ActionInputArgsMessage,
	SyncUserMessageActionRequest,
	void
>({
	async local(args) {
		// Create a Step
		const newStep: StepUser = createNewStepUser({
			sessionUserId: this.sessionUserId,
		});
		const newActionInput: ActionInputMessage = createNewActionInput({
			actionType: "message",
			stepUserId: newStep.step_user_id,
			stepAssistantId: null,
			args,
		});
		// To actually execute the action, add a Message
		const newMessage: Message = {
			message_id: getNewMessageId(),
			action_input_id: newActionInput.action_input_id,
			created_at: new Date().toISOString(),
			...args,
		};
		this.createMessageLocally(newMessage);
		// Create an ActionRun and ActionOutput to log the execution
		const newActionOutput: ActionOutputMessage = createNewActionOutput({
			actionType: "message",
			data: {
				new_message_id: newMessage.message_id,
			},
		});
		const newActionRun: ActionRunMessage = createNewActionRun({
			actionType: "message",
			actionInputId: newActionInput.action_input_id,
			actionOutputId: newActionOutput.action_output_id,
		});
		const newSyncUserMessageActionRequest: SyncUserMessageActionRequest = {
			step_user: newStep,
			action_input: newActionInput,
			message: newMessage,
			action_output: newActionOutput,
			action_run: newActionRun,
		};
		this.handleSyncUserMessageActionEvent(newSyncUserMessageActionRequest);
		return newSyncUserMessageActionRequest;
	},
	async remote(_, localResult) {
		await syncMessageActionRoute(localResult);
	},
	rollback(_args, localResult) {
		this.session?.stepsUser.delete(localResult.step_user.step_user_id);
		this.session?.actionInputs.delete(localResult.action_input.action_input_id);
		this.session?.messages.delete(localResult.message.message_id);
		this.session?.actionOutputs.delete(
			localResult.action_output.action_output_id,
		);
		this.session?.actionRuns.delete(localResult.action_run.action_run_id);
	},
	onRemoteSuccess() {},
});

export const executeActionOpenThread = createSyncedAction<
	AppState,
	ActionInputArgsOpenThread,
	SyncUserOpenThreadActionRequest,
	void
>({
	async local(args) {
		const newStep: StepUser = createNewStepUser({
			sessionUserId: this.sessionUserId,
		});
		const newActionInput: ActionInputOpenThread = createNewActionInput({
			actionType: "open_thread",
			stepUserId: newStep.step_user_id,
			stepAssistantId: null,
			args,
		});
		const newActionOutput: ActionOutputOpenThread = createNewActionOutput({
			actionType: "open_thread",
			data: {},
		});
		const newActionRun: ActionRunOpenThread = createNewActionRun({
			actionType: "open_thread",
			actionInputId: newActionInput.action_input_id,
			actionOutputId: newActionOutput.action_output_id,
		});
		const newSyncUserOpenThreadActionRequest: SyncUserOpenThreadActionRequest =
			{
				step_user: newStep,
				action_input: newActionInput,
				action_output: newActionOutput,
				action_run: newActionRun,
			};
		this.handleSyncUserOpenThreadActionEvent(
			newSyncUserOpenThreadActionRequest,
		);
		return newSyncUserOpenThreadActionRequest;
	},
	async remote(_, localResult) {
		await syncOpenThreadActionRoute(localResult);
	},
	rollback(_args, localResult) {
		this.session?.stepsUser.delete(localResult.step_user.step_user_id);
		this.session?.actionInputs.delete(localResult.action_input.action_input_id);
		this.session?.actionOutputs.delete(
			localResult.action_output.action_output_id,
		);
		this.session?.actionRuns.delete(localResult.action_run.action_run_id);
	},
	onRemoteSuccess() {},
});
