import { Button } from "@/components/ui/button";
import { useAppContext } from "@/contexts/AppContext";
import type { MessageWithMetadata } from "@api/schemas/messageWithMetadata";
import { PaperPlaneRight, X } from "@phosphor-icons/react";
import Link from "@tiptap/extension-link";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react-lite";

/**
 * Shows the content of a message.
 */
const MessageContent = observer(
	({ message }: { message: MessageWithMetadata }) => {
		const editor = useEditor(
			{
				extensions: [StarterKit, Link],
				content: message.content,
				editable: false,
			},
			[message.content],
		);
		const { user } = message;
		return (
			<div className="flex gap-2 px-4">
				<div className="flex-none pt-1">
					{message.agent_type === "user" ? (
						user.user_image_url ? (
							<img
								src={user.user_image_url}
								alt={user.user_email}
								className="h-8 w-8 rounded"
							/>
						) : (
							<div className="h-8 w-8 rounded bg-neutral-100" />
						)
					) : (
						<div className="h-8 w-8 rounded bg-neutral-100" />
					)}
				</div>
				<div className="flex grow flex-col">
					<span className="font-bold">
						{message.agent_type === "user" ? (
							user.user_first_name ? (
								<>
									{user.user_first_name} {user.user_last_name || ""}
								</>
							) : (
								user.user_email
							)
						) : (
							"Assistant"
						)}
					</span>
					<EditorContent className="w-full" editor={editor} />
				</div>
			</div>
		);
	},
);

/**
 * A message with replies hidden.
 */
const MessageWithReplyCount = observer(
	({ message }: { message: MessageWithMetadata }) => {
		const appContext = useAppContext();
		return (
			<div className="flex w-full flex-col">
				<MessageContent message={message} />
				{message.replies.length > 0 ? (
					<Button
						variant="ghost"
						className="justify-start rounded-none px-4 py-1 font-normal"
						onClick={() => {
							appContext.executeActionOpenThread({
								parent_message_id: message.message_id,
							});
						}}
					>
						{message.replies.length} replies
					</Button>
				) : (
					<Button
						variant="ghost"
						className="justify-start rounded-none px-4 py-1 font-normal"
						onClick={() => {
							appContext.executeActionOpenThread({
								parent_message_id: message.message_id,
							});
						}}
					>
						Reply
					</Button>
				)}
			</div>
		);
	},
);

/**
 * A message with replies expanded; views a thread of messages.
 */
const ActiveThreadViewer = observer(
	({ message }: { message: MessageWithMetadata }) => {
		const appContext = useAppContext();
		return (
			<div className="flex w-full flex-col">
				{/* Header */}
				<div className="flex h-8 w-full justify-between px-4">
					<div className="font-medium">Thread</div>
					<Button
						className="h-min w-min items-start p-1"
						variant="ghost"
						onClick={() => {
							appContext.executeActionOpenThread({
								parent_message_id: null,
							});
						}}
					>
						<X size={16} />
					</Button>
				</div>
				{/* Parent message */}
				<div className="border-b py-4">
					<MessageContent message={message} />
				</div>
				{/* Replies in thread */}
				<div className="pt-4">
					{message.replies.map((reply) => (
						<MessageWithReplyCount key={reply.message_id} message={reply} />
					))}
				</div>
			</div>
		);
	},
);

/**
 * Component that shows user messages. Either shows the open thread or all top-level messages.
 */
const MessagesViewer = observer(() => {
	const appContext = useAppContext();

	return (
		<div className="flex w-full grow flex-col gap-2 pt-3">
			{appContext.openThread ? (
				<ActiveThreadViewer message={appContext.openThread} />
			) : (
				appContext.allRootThreads.map((message) => (
					<MessageWithReplyCount key={message.message_id} message={message} />
				))
			)}
		</div>
	);
});

const MessageInput = observer(() => {
	const appContext = useAppContext();
	const editor = useEditor({
		extensions: [StarterKit, Link],
		content: appContext.messageInputContent,
		editable: true,
		onUpdate: ({ editor }) => {
			appContext.setMessageInputContent(editor.getHTML());
		},
		editorProps: {
			attributes: {
				class:
					"text-sm min-h-32 max-h-96 w-full p-2 overflow-y-auto outline-none",
			},
		},
	});
	return (
		<div className="flex w-full flex-col border focus-within:border-blue-300">
			<EditorContent className="w-full" editor={editor} />
			<div className="flex w-full">
				<div className="grow" />
				<Button
					className="h-8 w-8 p-0"
					onClick={() => {
						if (!editor) return;
						appContext.executeActionMessage({
							content: editor.getHTML(),
							parent_message_id: appContext.openThreadId,
							referenced_table_ids: [],
						});
						editor.commands.clearContent();
					}}
					disabled={editor?.isEmpty}
				>
					<PaperPlaneRight size={16} weight="fill" />
				</Button>
			</div>
		</div>
	);
});

/**
 * The right sidebar, which shows messages.
 */
export const RightSidebar = observer(() => {
	return (
		<div className="flex h-screen w-96 flex-none flex-col">
			<MessagesViewer />
			<div className="w-full p-4">
				<MessageInput />
			</div>
		</div>
	);
});
