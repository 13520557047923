import type { AppState } from "@/contexts/AppContext";
import type { WorkspaceUpdateEvent } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

export function attemptReconnect(this: AppState, userId: string) {
	Sentry.captureMessage("WebSocket connection lost", "error");

	if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
		console.error("Max reconnection attempts reached");
		toast.error("Unable to reconnect. Please refresh the page.");
		return;
	}
	toast.error("Connection lost. Reconnecting...");

	const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

	this.reconnectTimeoutId = setTimeout(() => {
		this.reconnectAttempts++;
		this.init({
			userId,
			isReconnect: true,
		});
	}, delay);
}

export function handleWorkspaceUpdate(
	this: AppState,
	update: WorkspaceUpdateEvent,
) {
	switch (update.type) {
		case "update_upload_status": {
			// update library
			const upload = this.workspace?.uploads.get(update.upload_id);
			if (upload) {
				upload.upload_status = update.status;
			}
			// if upload is in pending uploads, update that too
			const recentUpload = this.recentUploads.get(update.upload_id);
			if (recentUpload) {
				recentUpload.upload_status = update.status;
			}
			break;
		}
		case "upsert_uploads": {
			for (const upload of update.uploads) {
				// upsert upload in library
				this.workspace?.uploads.set(upload.upload_id, upload);
				this.uploadsFlexsearchIndex.add(upload);
				// if upload is in pending uploads, update that too
				const oldUpload = this.recentUploads.get(upload.upload_id);
				if (oldUpload) {
					this.recentUploads.set(upload.upload_id, upload);
				}
			}
			break;
		}
		case "upsert_folders": {
			for (const folder of update.folders) {
				this.workspace?.folders.set(folder.folder_id, folder);
			}
			break;
		}
		case "move_files": {
			for (const upload_id of update.upload_ids) {
				const upload = this.workspace?.uploads.get(upload_id);
				if (upload) {
					upload.upload_parent_id = update.new_parent_id;
				}
			}
			for (const folder_id of update.folder_ids) {
				const folder = this.workspace?.folders.get(folder_id);
				if (folder) {
					folder.folder_parent_id = update.new_parent_id;
				}
			}
			break;
		}
		case "delete_files": {
			for (const upload_id of update.upload_ids) {
				const upload = this.workspace?.uploads.get(upload_id);
				if (upload) {
					upload.file_deleted_at = new Date().toISOString();
				}
			}
			for (const folder_id of update.folder_ids) {
				const folder = this.workspace?.folders.get(folder_id);
				if (folder) {
					folder.file_deleted_at = new Date().toISOString();
				}
			}
			break;
		}
		case "rename_folder": {
			const folder = this.workspace?.folders.get(update.folder_id);
			if (folder) {
				folder.file_name = update.new_name;
			}
			break;
		}
		case "upsert_feed_channel": {
			const channel = update.feed_channel;
			this.workspace?.feedChannels.set(channel.feed_channel_id, channel);
			break;
		}
		case "update_feed_channel_refetching": {
			const channel = this.workspace?.feedChannels.get(update.feed_channel_id);
			if (channel) {
				channel.feed_channel_refetching = update.feed_channel_refetching;
			}
			break;
		}
		case "delete_feed_channels": {
			for (const channel_id of update.feed_channel_ids) {
				const channel = this.workspace?.feedChannels.get(channel_id);
				if (channel) {
					channel.file_deleted_at = new Date().toISOString();
				}
			}
			break;
		}
		case "upsert_feed_items": {
			for (const item of update.feed_items) {
				this.workspace?.feedItems.set(item.feed_item_id, item);
				this.feedItemsFlexsearchIndex.add(item);
			}
			break;
		}
		case "delete_feed_items": {
			for (const item_id of update.feed_item_ids) {
				const item = this.workspace?.feedItems.get(item_id);
				if (item) {
					// item.feed_item_deleted = true;
				}
			}
			break;
		}
		case "upsert_table": {
			const table = update.table;
			this.workspace?.tables.set(table.table_id, table);
			break;
		}
		case "delete_tables": {
			for (const table_id of update.table_ids) {
				const table = this.workspace?.tables.get(table_id);
				if (table) {
					table.file_deleted_at = new Date().toISOString();
				}
			}
			break;
		}
		case "rename_chat": {
			const chat = this.workspace?.chats.get(update.chat_id);
			if (chat) {
				chat.file_name = update.chat_title;
			}
			break;
		}
		default: {
			const _exhaustiveCheck: never = update;
			return _exhaustiveCheck;
		}
	}
}
