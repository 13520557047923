import { ColumnTypeIcons } from "@/components/ColumnTypeOptions";
import { BaseCellRenderer } from "@/components/TableComponent/Cells/CellRenderer";
import { ProxiedCell } from "@/components/TableComponent/Cells/ProxiedCell";
import { ProxiedGroupCell } from "@/components/TableComponent/Cells/ProxiedGroupCell";
import { ColumnCreationHeader } from "@/components/TableComponent/ColumnCreationHeader";
import {
	CategoryBackgroundColors,
	CategoryBorderColors,
	chooseRandomCategoryColor,
} from "@/components/TableComponent/categoryColors";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { type TableState, useTableContext } from "@/contexts/TableContext";
import { capitalizeFirstLetter } from "@/lib/formatting";
import {
	CategoryColor,
	type CategoryColumnMetadata,
	type CategoryMetadata,
	type ColumnId,
	type MaterializedColumn,
	type RowId,
} from "@api/schemas";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import {
	Code,
	Copy,
	DotsSixVertical,
	DotsThree,
	type IconProps,
	Link,
	Palette,
	Pencil,
	Plus,
	StarFour,
	Trash,
	Warning,
} from "@phosphor-icons/react";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
	type Header,
	createColumnHelper,
	flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { toast } from "sonner";

export const columnHelper = createColumnHelper<{ rowId: RowId }>();

export const selectorColumn = columnHelper.display({
	id: "select",
	enableResizing: false,
	header: ({ table }) => {
		const tableContext = useTableContext();
		<div className="flex h-full w-full items-center justify-end pr-2 pl-0.5">
			<Checkbox
				disabled={!tableContext.editable}
				checked={
					table.getIsAllRowsSelected()
						? true
						: table.getIsSomeRowsSelected()
							? "indeterminate"
							: false
				}
				onCheckedChange={(checked) =>
					table.toggleAllRowsSelected(checked === true)
				}
			/>
		</div>;
	},
	cell: ({ row }) => {
		const tableContext = useTableContext();
		const { attributes, listeners, setNodeRef } = useDraggable({
			id: row.original.rowId,
			disabled: !tableContext.editable,
			// No need to pass selectedRowIds here since we access them from state directly
		});

		return (
			<div
				className="flex h-8 w-full items-center justify-between gap-1 pr-2"
				onClick={(e) => {
					e.stopPropagation();
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.stopPropagation();
					}
				}}
			>
				<button
					className={clsx(
						"flex text-lg text-neutral-500 opacity-0",
						tableContext.editable && "group-hover/table-row:opacity-100",
					)}
					ref={setNodeRef}
					{...attributes}
					{...listeners}
				>
					<DotsSixVertical weight="bold" />
				</button>
				<Checkbox
					disabled={!tableContext.editable}
					checked={row.getIsSelected()}
					onCheckedChange={(checked) => row.toggleSelected(checked === true)}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
		);
	},
	size: 48,
	maxSize: 48,
});

const CategoryOption = observer(
	({
		category,
		columnId,
	}: { category: CategoryMetadata; columnId: ColumnId }) => {
		const tableContext = useTableContext();
		const [editing, setEditing] = useState(false);
		const [newValue, setNewValue] = useState(category.value);
		const inputRef = useRef<HTMLInputElement>(null);

		const renameCategory = () => {
			if (newValue === category.value) {
				setEditing(false);
				return;
			}

			if (newValue.trim().length === 0) {
				setNewValue(category.value);
				setEditing(false);
				toast.error("Category name can't be empty");
				return;
			}

			tableContext.renameColumnCategory({
				columnId,
				oldCategory: category.value,
				newCategory: newValue,
			});
			setEditing(false);
		};

		const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Escape") {
				setEditing(false);
				return;
			}
			if (e.key === "Enter") {
				renameCategory();
			}
		};

		return (
			<AlertDialog>
				<div
					key={category.value}
					className="flex items-center justify-between gap-2 px-1 py-1"
				>
					{editing ? (
						<Input
							value={newValue}
							onChange={(e) => setNewValue(e.target.value)}
							onKeyDown={onKeydown}
							onBlur={renameCategory}
							ref={inputRef}
						/>
					) : (
						<Badge
							variant="outline"
							className={clsx(
								CategoryBackgroundColors[category.color],
								CategoryBorderColors[category.color],
								"text-neutral-700",
							)}
						>
							{category.value}
						</Badge>
					)}
					<DropdownMenu>
						<DropdownMenuTrigger className="shrink-0 rounded p-1 hover:bg-neutral-100">
							<DotsThree weight="bold" />
						</DropdownMenuTrigger>
						<DropdownMenuContent
							align="end"
							// Prevent the menu from focusing back on the trigger when it closes,
							// so we can keep the focus on the input field
							onCloseAutoFocus={(e) => e.preventDefault()}
						>
							<DropdownMenuItem
								className="flex items-center gap-2 text-neutral-500"
								onClick={(e) => {
									setEditing(true);
									setTimeout(() => {
										inputRef.current?.focus();
									}, 0);
									e.stopPropagation();
								}}
							>
								<Pencil className="text-lg" /> Rename
							</DropdownMenuItem>

							<DropdownMenuSub>
								<DropdownMenuSubTrigger className="flex items-center gap-2 text-neutral-500">
									<Palette className="text-lg" /> Change color
								</DropdownMenuSubTrigger>
								<DropdownMenuPortal>
									<DropdownMenuSubContent>
										{Object.values(CategoryColor).map((color) => (
											<DropdownMenuItem
												key={color}
												className="gap-2"
												onClick={() => {
													tableContext.changeCategoryColor({
														columnId,
														categoryValue: category.value,
														newColor: color,
													});
												}}
											>
												<div
													className={clsx(
														"h-4 w-4 rounded-full",
														CategoryBackgroundColors[color],
														CategoryBorderColors[color],
													)}
												/>
												{capitalizeFirstLetter(color)}
											</DropdownMenuItem>
										))}
									</DropdownMenuSubContent>
								</DropdownMenuPortal>
							</DropdownMenuSub>

							<AlertDialogTrigger asChild>
								<DropdownMenuItem
									className="flex items-center gap-2 text-neutral-500"
									onClick={() => {
										tableContext.removeColumnCategory({
											columnId,
											categoryToRemove: category.value,
										});
									}}
								>
									<Trash className="text-lg" /> Delete
								</DropdownMenuItem>
							</AlertDialogTrigger>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
				{/* Deletion confirmation dialog */}
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Delete option?</AlertDialogTitle>
						<AlertDialogDescription>
							This will permanently delete the option from the column. Cells
							with this option will be set to empty.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel className="w-1/2">Cancel</AlertDialogCancel>
						<AlertDialogAction className="w-1/2">
							Delete option
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const CategoryOptionsEditor = observer(
	({
		columnId,
		columnMetadata,
	}: {
		columnId: ColumnId;
		columnMetadata: CategoryColumnMetadata;
	}) => {
		const [newValue, setNewValue] = useState("");
		const [showAddOption, setShowAddOption] = useState(false);
		const tableContext = useTableContext();

		const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Escape") {
				setShowAddOption(false);
				return;
			}

			if (e.key === "Enter") {
				tableContext.addColumnCategory({
					columnId,
					newCategory: { value: newValue, color: chooseRandomCategoryColor() },
					cellToSet: null,
				});

				setNewValue("");
			}
		};

		return (
			<div className="mt-2">
				<div className="flex items-center justify-between">
					<Label className="text-sm">Options</Label>
					{!showAddOption && (
						<button
							type="button"
							className="flex w-full max-w-max items-center gap-2 rounded-md p-2 text-sm hover:bg-neutral-100"
							onClick={() => setShowAddOption(true)}
						>
							<Plus />
						</button>
					)}
				</div>
				{showAddOption && (
					<Input
						value={newValue}
						onChange={(e) => setNewValue(e.target.value)}
						onKeyDown={handleKeyDown}
						placeholder="Add an option..."
					/>
				)}
				<div className="mt-1">
					{columnMetadata.categories
						? Object.values(columnMetadata.categories)
								.slice()
								.sort()
								.map((category) => (
									<CategoryOption
										key={category.value}
										category={category}
										columnId={columnId}
									/>
								))
						: !showAddOption && (
								<Button
									variant="outline"
									className="flex w-full items-center gap-2 text-sm"
									onClick={() => setShowAddOption(true)}
								>
									<Plus />
									Add an option
								</Button>
							)}
				</div>
			</div>
		);
	},
);

export const getColumnHeader = ({
	column,
	isPrimary,
}: { column: MaterializedColumn; isPrimary: boolean }) =>
	observer(() => {
		const tableContext = useTableContext();

		const [columnName, setColumnName] = useState(
			column.column_metadata.column_name,
		);
		const [columnDescription, setColumnDescription] = useState(
			column.column_metadata.column_description,
		);

		let ColumnIcon: React.ComponentType<IconProps>;
		let displayedColumnName: string;
		let isProxy = false;

		if (column.column_metadata.column_type === "proxy") {
			isProxy = true;
			const proxiedColumn = tableContext.getProxiedColumnMetadataById(
				// TODO: handle multiple proxied columns
				column.column_metadata.proxied_column_ids[0],
			);
			if (proxiedColumn) {
				ColumnIcon = ColumnTypeIcons[proxiedColumn.column_type];
				displayedColumnName = proxiedColumn.column_name || "Untitled";
			} else {
				ColumnIcon = (props: IconProps) => <Warning {...props} />;
				displayedColumnName = "Original column not found";
			}
		} else {
			ColumnIcon = ColumnTypeIcons[column.column_metadata.column_type];
			displayedColumnName = column.column_metadata.column_name || "Untitled";
		}

		return (
			<Popover>
				<PopoverTrigger asChild disabled={!tableContext.editable}>
					<button
						type="button"
						className="flex h-full w-full select-none items-center gap-2 truncate p-1 font-normal hover:bg-neutral-100"
					>
						<ColumnIcon className="shrink-0 text-lg text-neutral-500" />
						{displayedColumnName}
					</button>
				</PopoverTrigger>

				<PopoverContent align="start" className="w-72 p-2">
					{tableContext.devMode && (
						<button
							type="button"
							onClick={() => {
								navigator.clipboard.writeText(column.column_id);
								toast.success("Column ID copied to clipboard");
							}}
							className="mb-2 flex max-w-max items-center gap-1 break-all rounded-md border border-neutral-200 bg-neutral-100 px-1 py-0.5 text-neutral-600 text-xs"
						>
							<Code className="text-lg" /> {column.column_id}
						</button>
					)}

					{isProxy && (
						<div className="mb-2 flex max-w-max items-center gap-1 rounded-md border border-neutral-200 bg-neutral-100 px-1 py-0.5 text-neutral-600 text-sm">
							<Link weight="duotone" className="text-lg" /> Rollup column
						</div>
					)}

					{isPrimary && (
						<div className="mb-2 flex max-w-max items-center gap-1 rounded-md border border-neutral-200 bg-neutral-100 px-1 py-0.5 text-neutral-600 text-sm">
							<StarFour weight="duotone" className="text-lg" /> Primary column
						</div>
					)}

					<div className="">
						<Label>Label</Label>
						<Input
							disabled={isProxy}
							value={columnName}
							onChange={(e) => setColumnName(e.target.value)}
							onBlur={() => {
								if (columnName === column.column_metadata.column_name) {
									return;
								}
								if (columnName.trim().length === 0) {
									setColumnName(column.column_metadata.column_name);
									toast.error("Column name can't be empty");
									return;
								}
								tableContext.updateColumnMetadata({
									columnId: column.column_id,
									columnDescription: null,
									columnName,
								});
							}}
							placeholder="Label..."
						/>
					</div>
					<div>
						<Label>Description</Label>
						<Input
							disabled={isProxy}
							value={columnDescription}
							onChange={(e) => setColumnDescription(e.target.value)}
							onBlur={() => {
								if (
									columnDescription ===
									column.column_metadata.column_description
								) {
									return;
								}
								tableContext.updateColumnMetadata({
									columnId: column.column_id,
									columnDescription,
									columnName: null,
								});
							}}
							placeholder="Description..."
						/>
					</div>

					<div>
						{column.column_metadata.column_type === "category" && (
							<CategoryOptionsEditor
								columnId={column.column_id}
								columnMetadata={column.column_metadata}
							/>
						)}
					</div>

					<DropdownMenuSeparator className="mt-4" />

					{isPrimary ? (
						<Button variant="ghost" disabled className="mt-2 w-full">
							Primary columns can't be deleted
						</Button>
					) : (
						<AlertDialog>
							<AlertDialogTrigger
								onClick={(e) => {
									e.stopPropagation();
								}}
								asChild
							>
								<Button
									variant="outline"
									className="mt-2 flex w-full items-center gap-2 text-neutral-700"
								>
									<Trash />
									Delete column
								</Button>
							</AlertDialogTrigger>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>Delete column?</AlertDialogTitle>
									<AlertDialogDescription>
										This will permanently delete the column and its cells.
									</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel className="w-1/2">
										Cancel
									</AlertDialogCancel>
									<AlertDialogAction
										onClick={() => {
											tableContext.deleteColumn({
												columnId: column.column_id,
											});
										}}
										className="w-1/2"
									>
										Delete column
									</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					)}
				</PopoverContent>
			</Popover>
		);
	});

export const DraggableHeader = observer(
	({
		header,
		overId,
		draggedColumnId,
	}: {
		header: Header<
			{
				rowId: RowId;
			},
			unknown
		>;
		overId: ColumnId | null;
		draggedColumnId: ColumnId | null;
	}) => {
		const { setNodeRef: setDropNodeRef } = useDroppable({
			id: header.column.id,
		});

		const tableContext = useTableContext();

		const {
			attributes,
			listeners,
			setNodeRef: setDragNodeRef,
		} = useDraggable({
			id: header.column.id,
			disabled: !tableContext.editable,
		});

		const isDragging = draggedColumnId === header.column.id;

		// Disallow dragging the select and 'create column' headers
		const isDraggable =
			header.column.id !== "select" && header.column.id !== "create_column";

		return (
			<>
				{overId === header.column.id && (
					<div
						key={`drop-indicator-${header.column.id}`}
						className="relative z-50 h-8 w-0 p-0 ring-1 ring-blue-500"
					/>
				)}
				<div
					aria-label="th"
					className={clsx(
						"relative z-10 h-8 whitespace-nowrap border-b p-0 text-left font-semibold text-sm",
						header.column.id !== "create_column" && "border-r",
						isDragging && "bg-blue-50 opacity-50",
					)}
					style={{
						width: `calc(var(--header-${header.id}-size) * 1px)`,
					}}
					ref={setDropNodeRef}
				>
					<div
						className="relative z-10 flex h-full min-w-0 grow"
						ref={setDragNodeRef}
						{...attributes}
						{...listeners}
					>
						{header.isPlaceholder
							? null
							: flexRender(header.column.columnDef.header, header.getContext())}
					</div>
					{tableContext.editable && isDraggable ? (
						<button
							{...{
								onDoubleClick: () => header.column.resetSize(),
								onMouseDown: header.getResizeHandler(),
								onTouchStart: header.getResizeHandler(),
								className: `h-full cursor-ew-resize	 absolute right-0 z-20 w-1 top-0 bg-neutral-500 opacity-0 hover:opacity-100 ${
									header.column.getIsResizing() ? "opacity-100" : ""
								}`,
							}}
						/>
					) : null}
				</div>
			</>
		);
	},
);

export const getColumns = (tableContext: TableState) => () => {
	const columns = [
		selectorColumn,
		...tableContext.sortedColumns.map((column) => {
			return columnHelper.display({
				id: column.column_id,
				header: getColumnHeader({
					column,
					isPrimary:
						column.column_id === tableContext.table.root.primaryColumnId,
				}),
				cell: observer(({ row: tanstackRow, column: tanstackColumn }) => {
					const tableContext = useTableContext();
					const rowId = tanstackRow.original.rowId;
					const columnId = tanstackColumn.id as ColumnId;

					const columnMetadata = column.column_metadata;
					const cellValue = tableContext.getCellValue(rowId, columnId);

					if (columnMetadata.column_type === "proxy") {
						return (
							<ProxiedCell
								rowId={rowId}
								columnId={columnId}
								cellValue={cellValue}
								columnMetadata={columnMetadata}
								isProxy={true}
							/>
						);
					}
					if (columnMetadata.column_type === "proxy_group") {
						return (
							<ProxiedGroupCell
								rowId={rowId}
								columnId={columnId}
								cellValue={cellValue}
								columnMetadata={columnMetadata}
								isProxy={true}
							/>
						);
					}
					return (
						<BaseCellRenderer
							rowId={rowId}
							columnId={columnId}
							cellValue={cellValue}
							columnMetadata={columnMetadata}
							isProxy={false}
						/>
					);
				}),
				size: column.column_width ?? 320,
			});
		}),
	];
	if (tableContext.editable) {
		columns.push(
			columnHelper.display({
				id: "create_column",
				header: ColumnCreationHeader,
			}),
		);
	}
	if (tableContext.devMode) {
		columns.splice(
			0,
			0,
			columnHelper.display({
				id: "rowId",
				header: () => (
					<div className="flex h-full w-full select-none items-center gap-2 truncate p-1 font-normal hover:bg-neutral-100">
						<Code />
						Row ID
					</div>
				),
				cell: ({ row }) => (
					<div className="flex items-center gap-1 p-1 text-xs">
						<button
							type="button"
							className="rounded-md border p-1 hover:bg-neutral-100"
							onClick={() => {
								navigator.clipboard.writeText(row.original.rowId);
								toast.success("Row ID copied to clipboard");
							}}
						>
							<Copy />
						</button>
						<pre className="text-neutral-500">{row.original.rowId}</pre>
					</div>
				),
				size: 300,
			}),
		);
	}
	return columns;
};
