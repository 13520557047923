import type {
	ActionId,
	ActionInputId,
	ActionOutputId,
	ActionRunId,
	CellId,
	ChatId,
	ColumnId,
	FeedChannelId,
	FeedItemId,
	FilterId,
	FolderId,
	MessageId,
	RowId,
	SessionUserId,
	StepId,
	StepUserId,
	TableId,
	UploadId,
	UserId,
} from "@api/schemas";
import { ulid } from "ulidx";

export type FileId = string & { _brand: "fileId" };

type IdType =
	| "step"
	| "action"
	| "feed-channel"
	| "feed-item"
	| "folder"
	| "chat"
	| "table"
	| "table-column"
	| "table-row"
	| "table-cell"
	| "upload"
	| "file"
	// Session types
	| "session-user"
	| "step-user"
	| "message"
	| "action-input"
	| "action-output"
	| "action-run"
	| "filter";

type IdMap = {
	step: StepId;
	action: ActionId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	folder: FolderId;
	chat: ChatId;
	table: TableId;
	"table-column": ColumnId;
	"table-row": RowId;
	"table-cell": CellId;
	upload: UploadId;
	user: UserId;
	file: FileId;
	// Session types
	"session-user": SessionUserId;
	"step-user": StepUserId;
	message: MessageId;
	"action-input": ActionInputId;
	"action-output": ActionOutputId;
	"action-run": ActionRunId;
	filter: FilterId;
};

const generateId = <T extends IdType>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}` as IdMap[T];
};

export const newStepId = () => generateId("step");
export const newActionId = () => generateId("action");
export const newFeedChannelId = () => generateId("feed-channel");
export const newFeedItemId = () => generateId("feed-item");
export const newFolderId = () => generateId("folder");
export const newChatId = () => generateId("chat");
export const newTableId = () => generateId("table");
export const newColumnId = () => generateId("table-column");
export const newRowId = () => generateId("table-row");
export const formatCellId = ({
	rowId,
	columnId,
}: { rowId: RowId; columnId: ColumnId }) => `${rowId}:${columnId}` as CellId;
export const newUploadId = () => generateId("upload");
export const newFilterId = () => generateId("filter");
// Session types
export const getNewSessionUserId = () => generateId("session-user");
export const getNewMessageId = () => generateId("message");
export const getNewStepUserId = () => generateId("step-user");
export const getNewActionInputId = () => generateId("action-input");
export const getNewActionOutputId = () => generateId("action-output");
export const getNewActionRunId = () => generateId("action-run");
