import { CommandKDialog } from "@/components/CommandK";
import { RightSidebar } from "@/components/RightSidebar";
import { Sidebar } from "@/components/Sidebar";

export const Layout = ({ children }: { children: React.ReactNode }) => {
	return (
		<div className="flex h-screen w-screen">
			<CommandKDialog />

			<Sidebar />
			<div className="flex h-full min-h-0 min-w-0 shrink grow overflow-visible bg-neutral-100 p-2">
				<div className="h-full grow overflow-hidden rounded-lg border border-neutral-200 bg-white shadow-sm">
					{children}
				</div>
			</div>
			<RightSidebar />
		</div>
	);
};
