import { BaseCellRenderer } from "@/components/TableComponent/Cells/CellRenderer";
import type { TableCellProps } from "@/components/TableComponent/cellTypes";
import { useTableContext } from "@/contexts/TableContext";
import type { ProxyColumnMetadata } from "@api/schemas/proxyColumnMetadata";
import { observer } from "mobx-react-lite";

export const ProxiedCell = observer(
	({ rowId, columnId }: TableCellProps<ProxyColumnMetadata>) => {
		const tableContext = useTableContext();

		const cellValue = tableContext.getCellValue(rowId, columnId);

		if (!cellValue) {
			return null;
		}
		if (cellValue.cell_value_type !== "proxy") {
			return null;
		}

		const proxiedColumnMetadata = tableContext.getProxiedColumnMetadataById(
			cellValue.cell_value.table_column_id,
		);

		if (!proxiedColumnMetadata) {
			return null;
		}

		if (
			proxiedColumnMetadata.column_type === "proxy" ||
			proxiedColumnMetadata.column_type === "proxy_group"
		) {
			throw new Error("Proxied columns cannot be proxies themselves");
		}

		const proxiedCellValue = tableContext.getProxiedCellValue(
			cellValue.cell_value.table_row_id,
			cellValue.cell_value.table_column_id,
		);

		return (
			<BaseCellRenderer
				rowId={rowId}
				columnId={columnId}
				cellValue={proxiedCellValue}
				columnMetadata={proxiedColumnMetadata}
				isProxy={true}
			/>
		);
	},
);
