import { API_ENDPOINT_WS, IS_DEV } from "@/config";
import {
	type CommandKPage,
	getLastCmdKPage,
	openCmdKPage,
	popCmdKPage,
	pushCmdKPage,
	setCmdKOpen,
} from "@/contexts/AppContext/CmdK";
import {
	feedChannelImmediateChildren,
	feedChannelNodes,
	feedTreeChildrenAccessor,
} from "@/contexts/AppContext/FeedTreeHandlers";
import {
	addFeedChannelAction,
	deleteFeedChannelsAction,
	feedChannelsById,
	feedItemsById,
	searchFeedItemsByMetadata,
	sortedFeedChannels,
	sortedFeedItemsByChannel,
} from "@/contexts/AppContext/Feeds";
import {
	deleteChatAction,
	deleteChatLocally,
	formatChatPublicLink,
	navigateToNewChat,
	renameChatAction,
	renameChatLocally,
	sortedChats,
	toggleChatPublicAction,
} from "@/contexts/AppContext/Research";
import {
	createActionInputLocally,
	createActionOutputLocally,
	createActionRunLocally,
	createMessageLocally,
	createStepUserLocally,
	executeActionMessage,
	executeActionOpenThread,
} from "@/contexts/AppContext/Session";
import {
	folderImmediateChildren,
	getFolderDescendants,
	rootDirectoryNodes,
	treeChildrenAccessor,
	treeMoveHandler,
	treeMoveHandlerAction,
} from "@/contexts/AppContext/TreeHandlers";
import {
	createFolderAction,
	createUpload,
	deleteFolderAction,
	deleteMultiple,
	deleteUploadAction,
	downloadOriginalUploadFile,
	downloadUploadPdf,
	renameFolderAction,
	searchUploadsByMetadata,
	sortedIndexedUploads,
	updateUploadMetadataAction,
} from "@/contexts/AppContext/Uploads";
import {
	attemptReconnect,
	handleWorkspaceUpdate,
} from "@/contexts/AppContext/WorkspaceUpdates";
import {
	createComputedTableAction,
	createTableAction,
	deleteTablesAction,
	renameTableAction,
	renameTableLocally,
} from "@/contexts/TableContext/TableHandlers";
import { getNewSessionUserId } from "@/idGenerators";
import { bootstrapSession } from "@api/fastAPI";
import type {
	ActionInput,
	ActionInputId,
	ActionOutput,
	ActionOutputId,
	ActionRun,
	ActionRunId,
	AgentType,
	ChatId,
	ChatMetadata,
	FeedChannel,
	FeedChannelId,
	FeedItemId,
	FeedItemMetadata,
	Folder,
	FolderId,
	Message,
	MessageId,
	MessageWithMetadata,
	SessionAssistant,
	SessionAssistantId,
	SessionEvent,
	SessionId,
	SessionUser,
	SessionUserId,
	StepAssistant,
	StepAssistantId,
	StepUser,
	StepUserId,
	SyncUserMessageActionRequest,
	SyncUserOpenThreadActionRequest,
	TableId,
	TableMetadata,
	Upload,
	UploadId,
	UserId,
	WorkspaceUpdateEvent,
} from "@api/schemas";
import type { User } from "@api/schemas/user";
import { useAuth, useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import flexsearch from "flexsearch";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

interface Workspace {
	userId: UserId;
	uploads: Map<UploadId, Upload>;
	folders: Map<FolderId, Folder>;
	chats: Map<ChatId, ChatMetadata>;
	feedChannels: Map<FeedChannelId, FeedChannel>;
	feedItems: Map<FeedItemId, FeedItemMetadata>;
	tables: Map<TableId, TableMetadata>;
	users: Map<UserId, User>;
}

interface Session {
	sessionsUser: Map<SessionUserId, SessionUser>;
	sessionsAssistant: Map<SessionAssistantId, SessionAssistant>;
	messages: Map<MessageId, Message>;
	stepsUser: Map<StepUserId, StepUser>;
	stepsAssistant: Map<StepAssistantId, StepAssistant>;
	actionInputs: Map<ActionInputId, ActionInput>;
	actionOutputs: Map<ActionOutputId, ActionOutput>;
	actionRuns: Map<ActionRunId, ActionRun>;
}

export class AppState {
	workspace: Workspace | null = null;

	sessionUserId: SessionUserId;
	session: Session | null = null;
	uploadsFlexsearchIndex = new flexsearch.Document({
		tokenize: "full",
		document: {
			id: "upload_id",
			index: ["upload_title", "upload_subtitle", "upload_authors", "file_name"],
		},
	});
	feedItemsFlexsearchIndex = new flexsearch.Document({
		tokenize: "full",
		document: {
			id: "feed_item_id",
			index: [
				"file_name",
				"feed_item_subtitle",
				"feed_item_description",
				"feed_item_link",
				"feed_item_author",
			],
		},
	});

	pdfScale = 1;
	showSidebar = true;

	newChatUploadIds: Set<UploadId> = new Set();
	newChatFeedChannelIds: Set<FeedChannelId> = new Set();

	navigate: ReturnType<typeof useNavigate>;
	getToken: ReturnType<typeof useAuth>["getToken"];

	// Command K
	cmdKOpen = false;
	cmdKPages: CommandKPage[] = [];
	showAddFeedDialog = false;
	showCreateTableDialog = false;
	showCreateComputedTableDialog = false;

	// WebSocket for workspace-level updates
	ws: WebSocket | null = null;
	wsConnected = false;
	reconnectAttempts = 0;
	reconnectTimeoutId: Timer | null = null;

	// Files currently being uploaded by the current user, not persisted across sessions
	recentUploads: Map<UploadId, Upload> = new Map();
	get recentUploadsArray() {
		return Array.from(this.recentUploads.values()).sort((a, b) =>
			a.upload_id.localeCompare(b.upload_id),
		);
	}

	// MessageInput state
	messageInputContent = "";
	setMessageInputContent = (content: string) => {
		this.messageInputContent = content;
	};

	constructor({
		navigate,
		getToken,
	}: {
		navigate: ReturnType<typeof useNavigate>;
		getToken: ReturnType<typeof useAuth>["getToken"];
	}) {
		this.navigate = navigate;
		this.getToken = getToken;
		makeAutoObservable(this);
		makePersistable(this, {
			name: "AppState",
			properties: ["pdfScale", "showSidebar"],
			storage: window.localStorage,
		});

		// Each client has a session user ID
		let sessionUserId = localStorage.getItem("sessionUserId");
		if (!sessionUserId) {
			sessionUserId = getNewSessionUserId();
			localStorage.setItem("sessionUserId", sessionUserId);
		}
		this.sessionUserId = sessionUserId as SessionUserId;
	}

	async init({
		userId,
		isReconnect,
	}: {
		userId: string;
		isReconnect: boolean;
	}) {
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in AppContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		bootstrapSession()
			.then((res) => {
				runInAction(() => {
					for (const upload of res.data.uploads) {
						this.uploadsFlexsearchIndex.add(upload);
					}
					for (const item of res.data.feed_items) {
						this.feedItemsFlexsearchIndex.add(item);
					}
					this.workspace = {
						uploads: new Map(
							res.data.uploads.map((upload) => [upload.upload_id, upload]),
						),
						folders: new Map(
							res.data.folders.map((folder) => [folder.folder_id, folder]),
						),
						chats: new Map(res.data.chats.map((chat) => [chat.chat_id, chat])),
						feedChannels: new Map(
							res.data.feed_channels.map((feedChannel) => [
								feedChannel.feed_channel_id,
								feedChannel,
							]),
						),
						feedItems: new Map(
							res.data.feed_items.map((feedItem) => [
								feedItem.feed_item_id,
								feedItem,
							]),
						),
						tables: new Map(
							res.data.tables.map((table) => [table.table_id, table]),
						),
						users: new Map(
							res.data.users.map((user) => [user.user_id as UserId, user]),
						),
						userId: userId as UserId,
					};
					this.session = {
						sessionsUser: new Map(
							res.data.session_users.map((user) => [
								user.session_user_id,
								user,
							]),
						),
						sessionsAssistant: new Map(
							res.data.session_assistants.map((assistant) => [
								assistant.session_assistant_id,
								assistant,
							]),
						),
						messages: new Map(
							res.data.messages.map((message) => [message.message_id, message]),
						),
						stepsUser: new Map(
							res.data.steps_user.map((step) => [step.step_user_id, step]),
						),
						stepsAssistant: new Map(
							res.data.steps_assistant.map((step) => [
								step.step_assistant_id,
								step,
							]),
						),
						actionInputs: new Map(
							res.data.action_inputs.map((input) => [
								input.action_input_id,
								input,
							]),
						),
						actionOutputs: new Map(
							res.data.action_outputs.map((output) => [
								output.action_output_id,
								output,
							]),
						),
						actionRuns: new Map(
							res.data.action_runs.map((run) => [run.action_run_id, run]),
						),
					};
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to initialize session. Please refresh the page.");
				}
			});

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/sessions/ws?token=${token}&session_user_id=${this.sessionUserId}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to workspace.");
				}
			});
		};

		ws.onmessage = (event) => {
			try {
				const data: WorkspaceUpdateEvent | SessionEvent = JSON.parse(
					event.data,
				);
				if ("session_event_type" in data) {
					this.#handleSessionEvent(data);
				} else {
					this.#handleWorkspaceUpdate(data);
				}
			} catch (e) {
				console.error("Error parsing websocket response JSON:", e);
				return;
			}
		};

		ws.onclose = (e) => {
			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}
			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this.#attemptReconnect(userId);
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this.#attemptReconnect(userId);
		};
	}

	/*
	Workspace updates channel methods
	*/
	#attemptReconnect = attemptReconnect.bind(this);
	#handleWorkspaceUpdate = handleWorkspaceUpdate.bind(this);
	handleSyncUserMessageActionEvent(req: SyncUserMessageActionRequest) {
		this.createStepUserLocally(req.step_user);
		this.createActionInputLocally(req.action_input);
		this.createMessageLocally(req.message);
		this.createActionOutputLocally(req.action_output);
		this.createActionRunLocally(req.action_run);
	}
	handleSyncUserOpenThreadActionEvent(req: SyncUserOpenThreadActionRequest) {
		this.createStepUserLocally(req.step_user);
		this.createActionInputLocally(req.action_input);
		this.createActionOutputLocally(req.action_output);
		this.createActionRunLocally(req.action_run);
	}
	#handleSessionEvent(event: SessionEvent) {
		switch (event.session_event_type) {
			case "user_message_action_event": {
				this.handleSyncUserMessageActionEvent(event);
				break;
			}
			case "user_open_thread_action_event": {
				this.handleSyncUserOpenThreadActionEvent(event);
				break;
			}
			case "assistant_message_action_event": {
				this.createMessageLocally(event.message);
				break;
			}
			default: {
				const _exhaustiveCheck: never = event;
				return _exhaustiveCheck;
			}
		}
	}
	/*
	Command K-related methods
	*/
	popCmdKPage = popCmdKPage.bind(this);
	pushCmdKPage = pushCmdKPage.bind(this);
	setCmdKOpen = setCmdKOpen.bind(this);
	openCmdKPage = openCmdKPage.bind(this);
	get lastCmdKPage() {
		return getLastCmdKPage.bind(this)();
	}

	/*
	Library-related methods
	*/
	get workspaceHasLoaded() {
		return this.workspace !== null;
	}
	get sortedIndexedUploads() {
		return sortedIndexedUploads.bind(this)();
	}
	getUploadById(uploadId: UploadId) {
		return this.workspace?.uploads.get(uploadId) ?? null;
	}
	getFolderById(folderId: FolderId) {
		return this.workspace?.folders.get(folderId) ?? null;
	}
	updateUploadMetadata = updateUploadMetadataAction.bind(this);
	createFolder = createFolderAction.bind(this);
	deleteFolder = deleteFolderAction.bind(this);
	deleteMultiple = deleteMultiple.bind(this);
	createUpload = createUpload.bind(this);
	deleteUpload = deleteUploadAction.bind(this);
	renameFolder = renameFolderAction.bind(this);
	downloadUploadPdf = downloadUploadPdf.bind(this);
	downloadOriginalUploadFile = downloadOriginalUploadFile.bind(this);
	searchUploadsByMetadata = searchUploadsByMetadata.bind(this);

	/*
	Library tree-related methods
	*/
	treeChildrenAccessor = treeChildrenAccessor.bind(this);
	getFolderDescendants = getFolderDescendants.bind(this);
	treeMoveHandlerAction = treeMoveHandlerAction.bind(this);
	treeMoveHandler = treeMoveHandler.bind(this);
	get rootDirectoryNodes() {
		return rootDirectoryNodes.bind(this)();
	}
	get folderImmediateChildren() {
		return folderImmediateChildren.bind(this)();
	}

	/*
	Research-related methods
	*/
	addChatLocally(chat: ChatMetadata) {
		this.workspace?.chats.set(chat.chat_id, chat);
	}
	deleteChat = deleteChatAction.bind(this);
	renameChatLocally = renameChatLocally.bind(this);
	renameChat = renameChatAction.bind(this);
	toggleChatPublic = toggleChatPublicAction.bind(this);
	deleteChatLocally = deleteChatLocally.bind(this);
	get sortedChats() {
		return sortedChats.bind(this)();
	}
	navigateToNewChat = navigateToNewChat.bind(this);
	formatChatPublicLink = formatChatPublicLink.bind(this);

	/*
	Feed-related methods
	*/
	addFeedChannel = addFeedChannelAction.bind(this);
	deleteFeedChannels = deleteFeedChannelsAction.bind(this);
	get feedItemsById() {
		return feedItemsById.bind(this)();
	}
	getFeedItemById(feedItemId: FeedItemId) {
		return this.workspace?.feedItems.get(feedItemId) ?? null;
	}
	get feedChannelsById() {
		return feedChannelsById.bind(this)();
	}
	get sortedFeedChannels() {
		return sortedFeedChannels.bind(this)();
	}
	get sortedFeedItemsByChannel() {
		return sortedFeedItemsByChannel.bind(this)();
	}
	searchFeedItemsByMetadata = searchFeedItemsByMetadata.bind(this);
	feedTreeChildrenAccessor = feedTreeChildrenAccessor.bind(this);
	get feedChannelNodes() {
		return feedChannelNodes.bind(this)();
	}
	get feedChannelImmediateChildren() {
		return feedChannelImmediateChildren.bind(this)();
	}

	/*
	User-related methods
	*/
	getUserById(userId: UserId) {
		return this.workspace?.users.get(userId) ?? null;
	}

	/*
	Table-related methods
	*/
	createTable = createTableAction.bind(this);
	createComputedTable = createComputedTableAction.bind(this);
	renameTableLocally = renameTableLocally.bind(this);
	renameTable = renameTableAction.bind(this);
	deleteTables = deleteTablesAction.bind(this);

	get tablesAsArray() {
		const allTables = this.workspace
			? Array.from(this.workspace.tables.values())
			: [];
		return allTables.filter((table) => !table.file_deleted_at);
	}
	getTableById(tableId: TableId) {
		return this.workspace?.tables.get(tableId) ?? null;
	}

	/*
	Session Methods
	*/
	get allMessagesWithMetadata(): Map<MessageId, MessageWithMetadata> {
		if (!this.session) {
			return new Map();
		}

		const getMessageMetadata = (
			message: Message,
		): { agent_type: AgentType; user: User; session_id: SessionId } | null => {
			try {
				const actionInput = this.session?.actionInputs.get(
					message.action_input_id,
				);
				if (!actionInput) {
					throw new Error("No action input found for message");
				}
				if (actionInput.step_user_id !== null) {
					const stepUser = this.session?.stepsUser.get(
						actionInput.step_user_id,
					);
					if (!stepUser) {
						throw new Error("No step user found for message");
					}
					const sessionUser = this.session?.sessionsUser.get(
						stepUser.session_user_id,
					);
					if (!sessionUser) {
						throw new Error("No session user found for step user");
					}
					const user = this.getUserById(sessionUser.user_id);
					if (!user) {
						throw new Error("No user found for session user");
					}
					return {
						agent_type: "user",
						user,
						session_id: sessionUser.session_id,
					};
				}
				if (actionInput.step_assistant_id !== null) {
					const stepAssistant = this.session?.stepsAssistant.get(
						actionInput.step_assistant_id,
					);
					if (!stepAssistant) {
						throw new Error("No step assistant found for message");
					}
					const sessionAssistant = this.session?.sessionsAssistant.get(
						stepAssistant.session_assistant_id,
					);
					if (!sessionAssistant) {
						throw new Error("No session assistant found for step assistant");
					}
					const user = this.getUserById(sessionAssistant.user_id);
					if (!user) {
						throw new Error("No user found for session assistant");
					}
					return {
						agent_type: "assistant",
						user,
						session_id: sessionAssistant.session_id,
					};
				}
				throw new Error("No step user or step assistant found for message");
			} catch (error) {
				Sentry.captureException(error, {
					extra: { messageId: message.message_id },
				});
				console.error("Error getting message metadata:", error);
				return null;
			}
		};

		const messagesWithMetadata: Map<MessageId, MessageWithMetadata> = new Map(
			Array.from(this.session?.messages.values() ?? [])
				.map((message) => {
					const metadata = getMessageMetadata(message);
					if (!metadata) return null;
					const messageWithMetadata: MessageWithMetadata = {
						...message,
						...metadata,
						replies: [],
					};
					return [message.message_id, messageWithMetadata];
				})
				.filter(
					(entry): entry is [MessageId, MessageWithMetadata] => entry !== null,
				),
		);

		// Handle the replies
		for (const message of messagesWithMetadata.values()) {
			if (message.parent_message_id !== null) {
				const parentMessage = messagesWithMetadata.get(
					message.parent_message_id,
				);
				if (parentMessage) {
					parentMessage.replies.push(message);
				} else {
					Sentry.captureMessage(
						`Parent message not found for message ${message.message_id}. Should never happen.`,
						"warning",
					);
					console.warn(
						`Parent message not found for message ${message.message_id}. Should never happen.`,
					);
				}
			}
		}

		return messagesWithMetadata;
	}

	get allRootThreads(): MessageWithMetadata[] {
		return Array.from(this.allMessagesWithMetadata.values()).filter(
			(message) => message.parent_message_id === null,
		);
	}

	getThread(parentMessageId: MessageId): MessageWithMetadata | null {
		const thread = this.allMessagesWithMetadata.get(parentMessageId);
		if (!thread) {
			Sentry.captureMessage(
				`No thread found for message ${parentMessageId}`,
				"warning",
			);
			console.warn(`No thread found for message ${parentMessageId}`);
			return null;
		}
		return thread;
	}

	getUserSessionIdForActionRun(actionRun: ActionRun): SessionUserId | null {
		try {
			const actionInput = this.session?.actionInputs.get(
				actionRun.action_input_id,
			);
			if (!actionInput) {
				throw new Error("No action input found for action run");
			}
			if (actionInput.step_user_id === null) {
				throw new Error("Action input is not a step user action");
			}
			const stepUser = this.session?.stepsUser.get(actionInput.step_user_id);
			if (!stepUser) {
				throw new Error("No step user found for action input");
			}
			const sessionUser = this.session?.sessionsUser.get(
				stepUser.session_user_id,
			);
			if (!sessionUser) {
				throw new Error("No session user found for step user");
			}
			return sessionUser.session_user_id;
		} catch (error) {
			Sentry.captureException(error, {
				extra: { actionRunId: actionRun.action_run_id },
			});
			console.error("Error getting user session ID for action run:", error);
			return null;
		}
	}

	get openThreadId(): MessageId | null {
		const sortedActionRuns = Array.from(
			this.session?.actionRuns.values() ?? [],
		).sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
		for (const actionRun of sortedActionRuns) {
			const sessionUserId = this.getUserSessionIdForActionRun(actionRun);
			if (sessionUserId !== this.sessionUserId) {
				continue;
			}
			if (actionRun.action_type === "open_thread") {
				const actionInput = this.session?.actionInputs.get(
					actionRun.action_input_id,
				);
				if (!actionInput) {
					Sentry.captureMessage(
						`No action input found for action run ${actionRun.action_run_id}. Should never happen.`,
						"warning",
					);
					console.warn(
						`No action input found for action run ${actionRun.action_run_id}. Should never happen.`,
					);
					continue;
				}
				if (actionInput.action_type !== "open_thread") {
					Sentry.captureMessage(
						`Action input is not an open thread action for action run ${actionRun.action_run_id}. Should never happen.`,
						"warning",
					);
					console.warn(
						`Action input is not an open thread action for action run ${actionRun.action_run_id}. Should never happen.`,
					);
					continue;
				}
				return actionInput.args.parent_message_id;
			}
		}
		return null;
	}

	get openThread(): MessageWithMetadata | null {
		const openThreadId = this.openThreadId;
		if (!openThreadId) {
			return null;
		}
		return this.getThread(openThreadId);
	}

	createStepUserLocally = createStepUserLocally.bind(this);
	createActionInputLocally = createActionInputLocally.bind(this);
	createMessageLocally = createMessageLocally.bind(this);
	createActionOutputLocally = createActionOutputLocally.bind(this);
	createActionRunLocally = createActionRunLocally.bind(this);

	executeActionMessage = executeActionMessage.bind(this);
	executeActionOpenThread = executeActionOpenThread.bind(this);
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const AppContext = createContext<AppState>(null as any);

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext must be used within an AppProvider");
	}

	return context;
};

let didInit = false;

export const AppProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const navigate = useNavigate();
	const { getToken } = useAuth();
	const { user } = useUser();
	const [appState] = useState(() => new AppState({ navigate, getToken }));
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	useEffect(() => {
		runInAction(() => {
			appState.showSidebar = !isSmallDevice;
		});
	}, [appState, isSmallDevice]);

	useEffect(
		function init() {
			if (!user) {
				return;
			}

			if (!didInit) {
				didInit = true;
				appState.init({ userId: user.id, isReconnect: false });
			}
		},
		[appState, user],
	);

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
