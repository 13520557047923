/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AddColumnCategoryRequest,
  BodyUploadToFolder,
  BodyUploadToRoot,
  BootstrapSessionResponse,
  ChangeCategoryColorRequest,
  ChatRenameRequest,
  ChatResponse,
  CreateColumnRequest,
  CreateComputedTableRequest,
  CreateFolderRequest,
  CreateRowRequest,
  CreateTableRequest,
  CrossrefResponse,
  DeleteChatParams,
  DeleteColumnRequest,
  DeleteFeedChannelRequest,
  DeleteFeedChannelResponse,
  DeleteFeedItemRequest,
  DeleteFeedItemResponse,
  DeleteFolderRequest,
  DeleteMultipleFilesRequest,
  DeleteRowsRequest,
  DeleteTableRequest,
  DeleteUploadRequest,
  DummyChatParams,
  ExportConversationParams,
  ExportConversationResponse,
  FeedItem,
  GetFeedItemParams,
  GetFilteredRowsRequest,
  GetTableLatestVersionResponse,
  GetTablePastVersionRouteParams,
  GoogleBooksResponse,
  GuessRssUrlParams,
  HTTPValidationError,
  IndexFeedRequest,
  InferUploadMetadataRequest,
  InferUploadMetadataResponse,
  ListTablesAndColumnsRouteParams,
  MaterializedColumn,
  MaterializedTable,
  MessageWithMetadata,
  MoveColumnRequest,
  MoveDirectoryNodesRequest,
  MoveRowsRequest,
  PreviewComputedTableRequest,
  PreviewComputedTableResponse,
  PreviewRssFeedParams,
  PreviewRssFeedResponse,
  QueryCrossrefRequest,
  QueryGoogleBooksRequest,
  RefetchFeedChannelParams,
  RemoveColumnCategoryRequest,
  RenameColumnCategoryRequest,
  RenameFolderRequest,
  ResizeColumnRequest,
  RowId,
  SearchFeedItemsRequest,
  SearchFeedItemsResponse,
  SearchLibraryRequest,
  SearchLibraryResponse,
  SearchRequest,
  SearchResponseOutput,
  SessionEvent,
  SyncUserMessageActionRequest,
  SyncUserOpenThreadActionRequest,
  TableId,
  TableMetadata,
  TableRenameRequest,
  TableResponse,
  ToggleChatPublicRequest,
  UpdateCellRequest,
  UpdateColumnMetadataRequest,
  UpdateUploadMetadata,
  UploadId,
  ViewPublicChatResponse,
  WorkspaceUpdateEvent
} from './schemas'




/**
 * @summary Search Library Handler
 */
export const searchLibraryHandler = (
    searchLibraryRequest: SearchLibraryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchLibraryResponse>> => {
    
    return axios.default.post(
      `/search/search_uploads`,
      searchLibraryRequest,options
    );
  }



export const getSearchLibraryHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchLibraryHandler>>, TError,{data: SearchLibraryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchLibraryHandler>>, TError,{data: SearchLibraryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchLibraryHandler>>, {data: SearchLibraryRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchLibraryHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchLibraryHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchLibraryHandler>>>
    export type SearchLibraryHandlerMutationBody = SearchLibraryRequest
    export type SearchLibraryHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Library Handler
 */
export const useSearchLibraryHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchLibraryHandler>>, TError,{data: SearchLibraryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchLibraryHandler>>,
        TError,
        {data: SearchLibraryRequest},
        TContext
      > => {

      const mutationOptions = getSearchLibraryHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Search Feed Items Handler
 */
export const searchFeedItemsHandler = (
    searchFeedItemsRequest: SearchFeedItemsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFeedItemsResponse>> => {
    
    return axios.default.post(
      `/search/search_feed_items`,
      searchFeedItemsRequest,options
    );
  }



export const getSearchFeedItemsHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError,{data: SearchFeedItemsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError,{data: SearchFeedItemsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchFeedItemsHandler>>, {data: SearchFeedItemsRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchFeedItemsHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchFeedItemsHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchFeedItemsHandler>>>
    export type SearchFeedItemsHandlerMutationBody = SearchFeedItemsRequest
    export type SearchFeedItemsHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Feed Items Handler
 */
export const useSearchFeedItemsHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError,{data: SearchFeedItemsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchFeedItemsHandler>>,
        TError,
        {data: SearchFeedItemsRequest},
        TContext
      > => {

      const mutationOptions = getSearchFeedItemsHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Search Handler
 */
export const searchHandler = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchResponseOutput>> => {
    
    return axios.default.post(
      `/search`,
      searchRequest,options
    );
  }



export const getSearchHandlerMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchHandler>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchHandler>>, TError,{data: SearchRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchHandler>>, {data: SearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchHandler(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchHandler>>>
    export type SearchHandlerMutationBody = SearchRequest
    export type SearchHandlerMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Handler
 */
export const useSearchHandler = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchHandler>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchHandler>>,
        TError,
        {data: SearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchHandlerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Download Pdf
 */
export const downloadPdf = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/pdf/${uploadId}`,options
    );
  }


export const getDownloadPdfQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/pdf/${uploadId}`] as const;
    }

    
export const getDownloadPdfQueryOptions = <TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadPdfQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadPdf>>> = ({ signal }) => downloadPdf(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadPdfQueryResult = NonNullable<Awaited<ReturnType<typeof downloadPdf>>>
export type DownloadPdfQueryError = AxiosError<HTTPValidationError>


export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Download Pdf
 */

export function useDownloadPdf<TData = Awaited<ReturnType<typeof downloadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDownloadPdfQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Original
 */
export const downloadOriginal = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/original/${uploadId}`,options
    );
  }


export const getDownloadOriginalQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/original/${uploadId}`] as const;
    }

    
export const getDownloadOriginalQueryOptions = <TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadOriginalQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadOriginal>>> = ({ signal }) => downloadOriginal(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadOriginalQueryResult = NonNullable<Awaited<ReturnType<typeof downloadOriginal>>>
export type DownloadOriginalQueryError = AxiosError<HTTPValidationError>


export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Download Original
 */

export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDownloadOriginalQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Cover
 */
export const getCover = (
    uploadId: UploadId,
    height: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/uploads/cover_image/${uploadId}/${height}.jpg`,options
    );
  }


export const getGetCoverQueryKey = (uploadId: UploadId,
    height: number,) => {
    return [`/uploads/cover_image/${uploadId}/${height}.jpg`] as const;
    }

    
export const getGetCoverQueryOptions = <TData = Awaited<ReturnType<typeof getCover>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    height: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCover>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoverQueryKey(uploadId,height);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCover>>> = ({ signal }) => getCover(uploadId,height, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && height), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCover>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCoverQueryResult = NonNullable<Awaited<ReturnType<typeof getCover>>>
export type GetCoverQueryError = AxiosError<HTTPValidationError>


export function useGetCover<TData = Awaited<ReturnType<typeof getCover>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    height: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCover>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCover>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCover<TData = Awaited<ReturnType<typeof getCover>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    height: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCover>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCover>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCover<TData = Awaited<ReturnType<typeof getCover>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    height: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCover>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Cover
 */

export function useGetCover<TData = Awaited<ReturnType<typeof getCover>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    height: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCover>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCoverQueryOptions(uploadId,height,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Upload Metadata
 */
export const updateUploadMetadata = (
    updateUploadMetadata: UpdateUploadMetadata, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/update_metadata`,
      updateUploadMetadata,options
    );
  }



export const getUpdateUploadMetadataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadata>>, TError,{data: UpdateUploadMetadata}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadata>>, TError,{data: UpdateUploadMetadata}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUploadMetadata>>, {data: UpdateUploadMetadata}> = (props) => {
          const {data} = props ?? {};

          return  updateUploadMetadata(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof updateUploadMetadata>>>
    export type UpdateUploadMetadataMutationBody = UpdateUploadMetadata
    export type UpdateUploadMetadataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Upload Metadata
 */
export const useUpdateUploadMetadata = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadata>>, TError,{data: UpdateUploadMetadata}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUploadMetadata>>,
        TError,
        {data: UpdateUploadMetadata},
        TContext
      > => {

      const mutationOptions = getUpdateUploadMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Folder
 */
export const createFolder = (
    createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/create_folder`,
      createFolderRequest,options
    );
  }



export const getCreateFolderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFolder>>, {data: CreateFolderRequest}> = (props) => {
          const {data} = props ?? {};

          return  createFolder(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFolderMutationResult = NonNullable<Awaited<ReturnType<typeof createFolder>>>
    export type CreateFolderMutationBody = CreateFolderRequest
    export type CreateFolderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Folder
 */
export const useCreateFolder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{data: CreateFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createFolder>>,
        TError,
        {data: CreateFolderRequest},
        TContext
      > => {

      const mutationOptions = getCreateFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Folder
 */
export const deleteFolder = (
    deleteFolderRequest: DeleteFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/delete_folder`,
      deleteFolderRequest,options
    );
  }



export const getDeleteFolderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFolder>>, TError,{data: DeleteFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFolder>>, TError,{data: DeleteFolderRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFolder>>, {data: DeleteFolderRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFolder(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFolderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFolder>>>
    export type DeleteFolderMutationBody = DeleteFolderRequest
    export type DeleteFolderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Folder
 */
export const useDeleteFolder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFolder>>, TError,{data: DeleteFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFolder>>,
        TError,
        {data: DeleteFolderRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename Folder
 */
export const renameFolder = (
    renameFolderRequest: RenameFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/rename_folder`,
      renameFolderRequest,options
    );
  }



export const getRenameFolderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFolder>>, TError,{data: RenameFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameFolder>>, TError,{data: RenameFolderRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameFolder>>, {data: RenameFolderRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameFolder(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameFolderMutationResult = NonNullable<Awaited<ReturnType<typeof renameFolder>>>
    export type RenameFolderMutationBody = RenameFolderRequest
    export type RenameFolderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Folder
 */
export const useRenameFolder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFolder>>, TError,{data: RenameFolderRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameFolder>>,
        TError,
        {data: RenameFolderRequest},
        TContext
      > => {

      const mutationOptions = getRenameFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Move Directory Nodes
 */
export const moveDirectoryNodes = (
    moveDirectoryNodesRequest: MoveDirectoryNodesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/move_directory_nodes`,
      moveDirectoryNodesRequest,options
    );
  }



export const getMoveDirectoryNodesMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveDirectoryNodes>>, {data: MoveDirectoryNodesRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveDirectoryNodes(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveDirectoryNodesMutationResult = NonNullable<Awaited<ReturnType<typeof moveDirectoryNodes>>>
    export type MoveDirectoryNodesMutationBody = MoveDirectoryNodesRequest
    export type MoveDirectoryNodesMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Directory Nodes
 */
export const useMoveDirectoryNodes = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError,{data: MoveDirectoryNodesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveDirectoryNodes>>,
        TError,
        {data: MoveDirectoryNodesRequest},
        TContext
      > => {

      const mutationOptions = getMoveDirectoryNodesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Upload
 */
export const deleteUpload = (
    deleteUploadRequest: DeleteUploadRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/delete_upload`,
      deleteUploadRequest,options
    );
  }



export const getDeleteUploadMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUpload>>, TError,{data: DeleteUploadRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUpload>>, TError,{data: DeleteUploadRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUpload>>, {data: DeleteUploadRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteUpload(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUploadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUpload>>>
    export type DeleteUploadMutationBody = DeleteUploadRequest
    export type DeleteUploadMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Upload
 */
export const useDeleteUpload = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUpload>>, TError,{data: DeleteUploadRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUpload>>,
        TError,
        {data: DeleteUploadRequest},
        TContext
      > => {

      const mutationOptions = getDeleteUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Multiple Files
 */
export const deleteMultipleFiles = (
    deleteMultipleFilesRequest: DeleteMultipleFilesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/uploads/delete_multiple_files`,
      deleteMultipleFilesRequest,options
    );
  }



export const getDeleteMultipleFilesMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMultipleFiles>>, TError,{data: DeleteMultipleFilesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMultipleFiles>>, TError,{data: DeleteMultipleFilesRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMultipleFiles>>, {data: DeleteMultipleFilesRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteMultipleFiles(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMultipleFilesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMultipleFiles>>>
    export type DeleteMultipleFilesMutationBody = DeleteMultipleFilesRequest
    export type DeleteMultipleFilesMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Multiple Files
 */
export const useDeleteMultipleFiles = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMultipleFiles>>, TError,{data: DeleteMultipleFilesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteMultipleFiles>>,
        TError,
        {data: DeleteMultipleFilesRequest},
        TContext
      > => {

      const mutationOptions = getDeleteMultipleFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Upload To Root
 */
export const uploadToRoot = (
    bodyUploadToRoot: BodyUploadToRoot, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {const formData = new FormData();
formData.append('file', bodyUploadToRoot.file)
formData.append('upload_id', bodyUploadToRoot.upload_id)
formData.append('infer_metadata', bodyUploadToRoot.infer_metadata.toString())

    
    return axios.default.post(
      `/uploads/upload_to_root`,
      formData,options
    );
  }



export const getUploadToRootMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToRoot>>, TError,{data: BodyUploadToRoot}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof uploadToRoot>>, TError,{data: BodyUploadToRoot}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadToRoot>>, {data: BodyUploadToRoot}> = (props) => {
          const {data} = props ?? {};

          return  uploadToRoot(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadToRootMutationResult = NonNullable<Awaited<ReturnType<typeof uploadToRoot>>>
    export type UploadToRootMutationBody = BodyUploadToRoot
    export type UploadToRootMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Upload To Root
 */
export const useUploadToRoot = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToRoot>>, TError,{data: BodyUploadToRoot}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadToRoot>>,
        TError,
        {data: BodyUploadToRoot},
        TContext
      > => {

      const mutationOptions = getUploadToRootMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Upload To Folder
 */
export const uploadToFolder = (
    bodyUploadToFolder: BodyUploadToFolder, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {const formData = new FormData();
formData.append('file', bodyUploadToFolder.file)
formData.append('upload_id', bodyUploadToFolder.upload_id)
formData.append('folder_id', bodyUploadToFolder.folder_id)
formData.append('infer_metadata', bodyUploadToFolder.infer_metadata.toString())

    
    return axios.default.post(
      `/uploads/upload_to_folder`,
      formData,options
    );
  }



export const getUploadToFolderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToFolder>>, TError,{data: BodyUploadToFolder}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof uploadToFolder>>, TError,{data: BodyUploadToFolder}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadToFolder>>, {data: BodyUploadToFolder}> = (props) => {
          const {data} = props ?? {};

          return  uploadToFolder(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadToFolderMutationResult = NonNullable<Awaited<ReturnType<typeof uploadToFolder>>>
    export type UploadToFolderMutationBody = BodyUploadToFolder
    export type UploadToFolderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Upload To Folder
 */
export const useUploadToFolder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToFolder>>, TError,{data: BodyUploadToFolder}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadToFolder>>,
        TError,
        {data: BodyUploadToFolder},
        TContext
      > => {

      const mutationOptions = getUploadToFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Crossref
 */
export const queryCrossref = (
    queryCrossrefRequest: QueryCrossrefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CrossrefResponse>> => {
    
    return axios.default.post(
      `/uploads/query_crossref`,
      queryCrossrefRequest,options
    );
  }



export const getQueryCrossrefMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryCrossref>>, {data: QueryCrossrefRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryCrossref(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryCrossrefMutationResult = NonNullable<Awaited<ReturnType<typeof queryCrossref>>>
    export type QueryCrossrefMutationBody = QueryCrossrefRequest
    export type QueryCrossrefMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Crossref
 */
export const useQueryCrossref = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryCrossref>>,
        TError,
        {data: QueryCrossrefRequest},
        TContext
      > => {

      const mutationOptions = getQueryCrossrefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Google Books
 */
export const queryGoogleBooks = (
    queryGoogleBooksRequest: QueryGoogleBooksRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GoogleBooksResponse>> => {
    
    return axios.default.post(
      `/uploads/query_google_books`,
      queryGoogleBooksRequest,options
    );
  }



export const getQueryGoogleBooksMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryGoogleBooks>>, {data: QueryGoogleBooksRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryGoogleBooks(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryGoogleBooksMutationResult = NonNullable<Awaited<ReturnType<typeof queryGoogleBooks>>>
    export type QueryGoogleBooksMutationBody = QueryGoogleBooksRequest
    export type QueryGoogleBooksMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Google Books
 */
export const useQueryGoogleBooks = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryGoogleBooks>>,
        TError,
        {data: QueryGoogleBooksRequest},
        TContext
      > => {

      const mutationOptions = getQueryGoogleBooksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Infer Upload Metadata
 */
export const inferUploadMetadata = (
    inferUploadMetadataRequest: InferUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    
    return axios.default.post(
      `/uploads/infer_metadata`,
      inferUploadMetadataRequest,options
    );
  }



export const getInferUploadMetadataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inferUploadMetadata>>, {data: InferUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  inferUploadMetadata(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InferUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof inferUploadMetadata>>>
    export type InferUploadMetadataMutationBody = InferUploadMetadataRequest
    export type InferUploadMetadataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Infer Upload Metadata
 */
export const useInferUploadMetadata = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof inferUploadMetadata>>,
        TError,
        {data: InferUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getInferUploadMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Chat
 */
export const deleteChat = (
    params: DeleteChatParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.delete(
      `/research/delete_chat`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteChatMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteChat>>, TError,{params: DeleteChatParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteChat>>, TError,{params: DeleteChatParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteChat>>, {params: DeleteChatParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteChat(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteChatMutationResult = NonNullable<Awaited<ReturnType<typeof deleteChat>>>
    
    export type DeleteChatMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Chat
 */
export const useDeleteChat = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteChat>>, TError,{params: DeleteChatParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteChat>>,
        TError,
        {params: DeleteChatParams},
        TContext
      > => {

      const mutationOptions = getDeleteChatMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename Chat
 */
export const renameChat = (
    chatRenameRequest: ChatRenameRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/research/rename_chat`,
      chatRenameRequest,options
    );
  }



export const getRenameChatMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameChat>>, TError,{data: ChatRenameRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameChat>>, TError,{data: ChatRenameRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameChat>>, {data: ChatRenameRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameChat(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameChatMutationResult = NonNullable<Awaited<ReturnType<typeof renameChat>>>
    export type RenameChatMutationBody = ChatRenameRequest
    export type RenameChatMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Chat
 */
export const useRenameChat = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameChat>>, TError,{data: ChatRenameRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameChat>>,
        TError,
        {data: ChatRenameRequest},
        TContext
      > => {

      const mutationOptions = getRenameChatMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary View Public Chat
 */
export const viewPublicChat = (
    chatId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ViewPublicChatResponse>> => {
    
    return axios.default.get(
      `/research/view_public_chat/${chatId}`,options
    );
  }


export const getViewPublicChatQueryKey = (chatId: string,) => {
    return [`/research/view_public_chat/${chatId}`] as const;
    }

    
export const getViewPublicChatQueryOptions = <TData = Awaited<ReturnType<typeof viewPublicChat>>, TError = AxiosError<HTTPValidationError>>(chatId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof viewPublicChat>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getViewPublicChatQueryKey(chatId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof viewPublicChat>>> = ({ signal }) => viewPublicChat(chatId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(chatId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof viewPublicChat>>, TError, TData> & { queryKey: QueryKey }
}

export type ViewPublicChatQueryResult = NonNullable<Awaited<ReturnType<typeof viewPublicChat>>>
export type ViewPublicChatQueryError = AxiosError<HTTPValidationError>


export function useViewPublicChat<TData = Awaited<ReturnType<typeof viewPublicChat>>, TError = AxiosError<HTTPValidationError>>(
 chatId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof viewPublicChat>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof viewPublicChat>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useViewPublicChat<TData = Awaited<ReturnType<typeof viewPublicChat>>, TError = AxiosError<HTTPValidationError>>(
 chatId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof viewPublicChat>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof viewPublicChat>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useViewPublicChat<TData = Awaited<ReturnType<typeof viewPublicChat>>, TError = AxiosError<HTTPValidationError>>(
 chatId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof viewPublicChat>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary View Public Chat
 */

export function useViewPublicChat<TData = Awaited<ReturnType<typeof viewPublicChat>>, TError = AxiosError<HTTPValidationError>>(
 chatId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof viewPublicChat>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getViewPublicChatQueryOptions(chatId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Toggle Chat Public
 */
export const toggleChatPublic = (
    toggleChatPublicRequest: ToggleChatPublicRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/research/toggle_chat_public`,
      toggleChatPublicRequest,options
    );
  }



export const getToggleChatPublicMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof toggleChatPublic>>, TError,{data: ToggleChatPublicRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof toggleChatPublic>>, TError,{data: ToggleChatPublicRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof toggleChatPublic>>, {data: ToggleChatPublicRequest}> = (props) => {
          const {data} = props ?? {};

          return  toggleChatPublic(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ToggleChatPublicMutationResult = NonNullable<Awaited<ReturnType<typeof toggleChatPublic>>>
    export type ToggleChatPublicMutationBody = ToggleChatPublicRequest
    export type ToggleChatPublicMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Toggle Chat Public
 */
export const useToggleChatPublic = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof toggleChatPublic>>, TError,{data: ToggleChatPublicRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof toggleChatPublic>>,
        TError,
        {data: ToggleChatPublicRequest},
        TContext
      > => {

      const mutationOptions = getToggleChatPublicMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Export Conversation
 */
export const exportConversation = (
    params: ExportConversationParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExportConversationResponse>> => {
    
    return axios.default.get(
      `/research/export_conversation`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getExportConversationQueryKey = (params: ExportConversationParams,) => {
    return [`/research/export_conversation`, ...(params ? [params]: [])] as const;
    }

    
export const getExportConversationQueryOptions = <TData = Awaited<ReturnType<typeof exportConversation>>, TError = AxiosError<HTTPValidationError>>(params: ExportConversationParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportConversation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportConversationQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportConversation>>> = ({ signal }) => exportConversation(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportConversation>>, TError, TData> & { queryKey: QueryKey }
}

export type ExportConversationQueryResult = NonNullable<Awaited<ReturnType<typeof exportConversation>>>
export type ExportConversationQueryError = AxiosError<HTTPValidationError>


export function useExportConversation<TData = Awaited<ReturnType<typeof exportConversation>>, TError = AxiosError<HTTPValidationError>>(
 params: ExportConversationParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportConversation>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportConversation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExportConversation<TData = Awaited<ReturnType<typeof exportConversation>>, TError = AxiosError<HTTPValidationError>>(
 params: ExportConversationParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportConversation>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exportConversation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExportConversation<TData = Awaited<ReturnType<typeof exportConversation>>, TError = AxiosError<HTTPValidationError>>(
 params: ExportConversationParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportConversation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Export Conversation
 */

export function useExportConversation<TData = Awaited<ReturnType<typeof exportConversation>>, TError = AxiosError<HTTPValidationError>>(
 params: ExportConversationParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportConversation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExportConversationQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bootstrap Session
 */
export const bootstrapSession = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BootstrapSessionResponse>> => {
    
    return axios.default.get(
      `/user/bootstrap_session`,options
    );
  }


export const getBootstrapSessionQueryKey = () => {
    return [`/user/bootstrap_session`] as const;
    }

    
export const getBootstrapSessionQueryOptions = <TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBootstrapSessionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bootstrapSession>>> = ({ signal }) => bootstrapSession({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData> & { queryKey: QueryKey }
}

export type BootstrapSessionQueryResult = NonNullable<Awaited<ReturnType<typeof bootstrapSession>>>
export type BootstrapSessionQueryError = AxiosError<unknown>


export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Bootstrap Session
 */

export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getBootstrapSessionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Guess the RSS feed URL from a given URL by fetching the HTML
and looking for the RSS feed link in the header
 * @summary Guess Rss Url
 */
export const guessRssUrl = (
    params: GuessRssUrlParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.post(
      `/feeds/guess_rss_url`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGuessRssUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof guessRssUrl>>, {params: GuessRssUrlParams}> = (props) => {
          const {params} = props ?? {};

          return  guessRssUrl(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GuessRssUrlMutationResult = NonNullable<Awaited<ReturnType<typeof guessRssUrl>>>
    
    export type GuessRssUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Guess Rss Url
 */
export const useGuessRssUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof guessRssUrl>>,
        TError,
        {params: GuessRssUrlParams},
        TContext
      > => {

      const mutationOptions = getGuessRssUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Preview an RSS feed by fetching the feed and returning the parsed feed
 * @summary Preview Rss Feed
 */
export const previewRssFeed = (
    params: PreviewRssFeedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    
    return axios.default.post(
      `/feeds/preview_rss_feed`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPreviewRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewRssFeed>>, {params: PreviewRssFeedParams}> = (props) => {
          const {params} = props ?? {};

          return  previewRssFeed(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PreviewRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof previewRssFeed>>>
    
    export type PreviewRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Rss Feed
 */
export const usePreviewRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof previewRssFeed>>,
        TError,
        {params: PreviewRssFeedParams},
        TContext
      > => {

      const mutationOptions = getPreviewRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Index a new RSS feed into Vespa and Postgres
 * @summary Index Rss Feed
 */
export const indexRssFeed = (
    indexFeedRequest: IndexFeedRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/feeds/index_rss_feed`,
      indexFeedRequest,options
    );
  }



export const getIndexRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexRssFeed>>, {data: IndexFeedRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexRssFeed(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IndexRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof indexRssFeed>>>
    export type IndexRssFeedMutationBody = IndexFeedRequest
    export type IndexRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Rss Feed
 */
export const useIndexRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexRssFeed>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof indexRssFeed>>,
        TError,
        {data: IndexFeedRequest},
        TContext
      > => {

      const mutationOptions = getIndexRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Refetch a feed channel by fetching the feed again and updating the feed items
 * @summary Refetch Feed Channel
 */
export const refetchFeedChannel = (
    params: RefetchFeedChannelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/feeds/refetch_feed_channel`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRefetchFeedChannelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refetchFeedChannel>>, {params: RefetchFeedChannelParams}> = (props) => {
          const {params} = props ?? {};

          return  refetchFeedChannel(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefetchFeedChannelMutationResult = NonNullable<Awaited<ReturnType<typeof refetchFeedChannel>>>
    
    export type RefetchFeedChannelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Refetch Feed Channel
 */
export const useRefetchFeedChannel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof refetchFeedChannel>>,
        TError,
        {params: RefetchFeedChannelParams},
        TContext
      > => {

      const mutationOptions = getRefetchFeedChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Retrieve the full feed item by ID
 * @summary Get Feed Item
 */
export const getFeedItem = (
    params: GetFeedItemParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FeedItem>> => {
    
    return axios.default.get(
      `/feeds/feed_item`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetFeedItemQueryKey = (params: GetFeedItemParams,) => {
    return [`/feeds/feed_item`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFeedItemQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItem>>> = ({ signal }) => getFeedItem(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedItemQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItem>>>
export type GetFeedItemQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItem>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItem>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Feed Item
 */

export function useGetFeedItem<TData = Awaited<ReturnType<typeof getFeedItem>>, TError = AxiosError<HTTPValidationError>>(
 params: GetFeedItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFeedItemQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Mark a feed item as deleted
 * @summary Delete Feed Item
 */
export const deleteFeedItem = (
    deleteFeedItemRequest: DeleteFeedItemRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteFeedItemResponse>> => {
    
    return axios.default.delete(
      `/feeds/delete_feed_item`,{data:
      deleteFeedItemRequest, ...options}
    );
  }



export const getDeleteFeedItemMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFeedItem>>, TError,{data: DeleteFeedItemRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFeedItem>>, TError,{data: DeleteFeedItemRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFeedItem>>, {data: DeleteFeedItemRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFeedItem(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFeedItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFeedItem>>>
    export type DeleteFeedItemMutationBody = DeleteFeedItemRequest
    export type DeleteFeedItemMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Feed Item
 */
export const useDeleteFeedItem = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFeedItem>>, TError,{data: DeleteFeedItemRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFeedItem>>,
        TError,
        {data: DeleteFeedItemRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFeedItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Mark a feed channel as deleted
 * @summary Delete Feed Channels
 */
export const deleteFeedChannels = (
    deleteFeedChannelRequest: DeleteFeedChannelRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteFeedChannelResponse>> => {
    
    return axios.default.delete(
      `/feeds/delete_feed_channel`,{data:
      deleteFeedChannelRequest, ...options}
    );
  }



export const getDeleteFeedChannelsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFeedChannels>>, TError,{data: DeleteFeedChannelRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFeedChannels>>, TError,{data: DeleteFeedChannelRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFeedChannels>>, {data: DeleteFeedChannelRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFeedChannels(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFeedChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFeedChannels>>>
    export type DeleteFeedChannelsMutationBody = DeleteFeedChannelRequest
    export type DeleteFeedChannelsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Feed Channels
 */
export const useDeleteFeedChannels = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFeedChannels>>, TError,{data: DeleteFeedChannelRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFeedChannels>>,
        TError,
        {data: DeleteFeedChannelRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFeedChannelsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a new table with a name and a primary column.
 * @summary Create Table Route
 */
export const createTableRoute = (
    createTableRequest: CreateTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableMetadata>> => {
    
    return axios.default.post(
      `/create`,
      createTableRequest,options
    );
  }



export const getCreateTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTableRoute>>, {data: CreateTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createTableRoute>>>
    export type CreateTableRouteMutationBody = CreateTableRequest
    export type CreateTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Table Route
 */
export const useCreateTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createTableRoute>>,
        TError,
        {data: CreateTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the latest version of a table.

Also lists table metadata and materialized columns for any parent tables.
 * @summary Get Table Latest Version
 */
export const getTableLatestVersion = (
    tableId: TableId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetTableLatestVersionResponse>> => {
    
    return axios.default.get(
      `/get_latest/${tableId}`,options
    );
  }


export const getGetTableLatestVersionQueryKey = (tableId: TableId,) => {
    return [`/get_latest/${tableId}`] as const;
    }

    
export const getGetTableLatestVersionQueryOptions = <TData = Awaited<ReturnType<typeof getTableLatestVersion>>, TError = AxiosError<HTTPValidationError>>(tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersion>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTableLatestVersionQueryKey(tableId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTableLatestVersion>>> = ({ signal }) => getTableLatestVersion(tableId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tableId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersion>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTableLatestVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getTableLatestVersion>>>
export type GetTableLatestVersionQueryError = AxiosError<HTTPValidationError>


export function useGetTableLatestVersion<TData = Awaited<ReturnType<typeof getTableLatestVersion>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersion>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableLatestVersion>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTableLatestVersion<TData = Awaited<ReturnType<typeof getTableLatestVersion>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersion>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableLatestVersion>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTableLatestVersion<TData = Awaited<ReturnType<typeof getTableLatestVersion>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersion>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Table Latest Version
 */

export function useGetTableLatestVersion<TData = Awaited<ReturnType<typeof getTableLatestVersion>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableLatestVersion>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTableLatestVersionQueryOptions(tableId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a past version of a table.
 * @summary Get Table Past Version Route
 */
export const getTablePastVersionRoute = (
    params: GetTablePastVersionRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedTable>> => {
    
    return axios.default.get(
      `/get_past_version`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTablePastVersionRouteQueryKey = (params: GetTablePastVersionRouteParams,) => {
    return [`/get_past_version`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTablePastVersionRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTablePastVersionRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTablePastVersionRoute>>> = ({ signal }) => getTablePastVersionRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTablePastVersionRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTablePastVersionRoute>>>
export type GetTablePastVersionRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTablePastVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTablePastVersionRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Table Past Version Route
 */

export function useGetTablePastVersionRoute<TData = Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTablePastVersionRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTablePastVersionRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTablePastVersionRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Renames a table.
 * @summary Rename Table Route
 */
export const renameTableRoute = (
    tableRenameRequest: TableRenameRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/rename_table`,
      tableRenameRequest,options
    );
  }



export const getRenameTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameTableRoute>>, TError,{data: TableRenameRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameTableRoute>>, TError,{data: TableRenameRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameTableRoute>>, {data: TableRenameRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameTableRoute>>>
    export type RenameTableRouteMutationBody = TableRenameRequest
    export type RenameTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Table Route
 */
export const useRenameTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameTableRoute>>, TError,{data: TableRenameRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameTableRoute>>,
        TError,
        {data: TableRenameRequest},
        TContext
      > => {

      const mutationOptions = getRenameTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Tables Route
 */
export const deleteTablesRoute = (
    deleteTableRequest: DeleteTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.delete(
      `/tables/delete_tables`,{data:
      deleteTableRequest, ...options}
    );
  }



export const getDeleteTablesRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTablesRoute>>, TError,{data: DeleteTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTablesRoute>>, TError,{data: DeleteTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTablesRoute>>, {data: DeleteTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteTablesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTablesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTablesRoute>>>
    export type DeleteTablesRouteMutationBody = DeleteTableRequest
    export type DeleteTablesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Tables Route
 */
export const useDeleteTablesRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTablesRoute>>, TError,{data: DeleteTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTablesRoute>>,
        TError,
        {data: DeleteTableRequest},
        TContext
      > => {

      const mutationOptions = getDeleteTablesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Inserts a new row at the end of the table.
 * @summary Create Row Route
 */
export const createRowRoute = (
    createRowRequest: CreateRowRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/create_row`,
      createRowRequest,options
    );
  }



export const getCreateRowRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRowRoute>>, TError,{data: CreateRowRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createRowRoute>>, TError,{data: CreateRowRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRowRoute>>, {data: CreateRowRequest}> = (props) => {
          const {data} = props ?? {};

          return  createRowRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateRowRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createRowRoute>>>
    export type CreateRowRouteMutationBody = CreateRowRequest
    export type CreateRowRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Row Route
 */
export const useCreateRowRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRowRoute>>, TError,{data: CreateRowRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createRowRoute>>,
        TError,
        {data: CreateRowRequest},
        TContext
      > => {

      const mutationOptions = getCreateRowRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a new column in a table. The column is inserted at the end of the table.
 * @summary Create Column Route
 */
export const createColumnRoute = (
    createColumnRequest: CreateColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/create_column`,
      createColumnRequest,options
    );
  }



export const getCreateColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createColumnRoute>>, TError,{data: CreateColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createColumnRoute>>, TError,{data: CreateColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createColumnRoute>>, {data: CreateColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  createColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createColumnRoute>>>
    export type CreateColumnRouteMutationBody = CreateColumnRequest
    export type CreateColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Column Route
 */
export const useCreateColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createColumnRoute>>, TError,{data: CreateColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createColumnRoute>>,
        TError,
        {data: CreateColumnRequest},
        TContext
      > => {

      const mutationOptions = getCreateColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Rows Route
 */
export const deleteRowsRoute = (
    deleteRowsRequest: DeleteRowsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/delete_rows`,
      deleteRowsRequest,options
    );
  }



export const getDeleteRowsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRowsRoute>>, TError,{data: DeleteRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRowsRoute>>, TError,{data: DeleteRowsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRowsRoute>>, {data: DeleteRowsRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRowsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRowsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRowsRoute>>>
    export type DeleteRowsRouteMutationBody = DeleteRowsRequest
    export type DeleteRowsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Rows Route
 */
export const useDeleteRowsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRowsRoute>>, TError,{data: DeleteRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRowsRoute>>,
        TError,
        {data: DeleteRowsRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRowsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a single column from a table.
 * @summary Delete Column Route
 */
export const deleteColumnRoute = (
    deleteColumnRequest: DeleteColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/delete_column`,
      deleteColumnRequest,options
    );
  }



export const getDeleteColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteColumnRoute>>, TError,{data: DeleteColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteColumnRoute>>, TError,{data: DeleteColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteColumnRoute>>, {data: DeleteColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteColumnRoute>>>
    export type DeleteColumnRouteMutationBody = DeleteColumnRequest
    export type DeleteColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Column Route
 */
export const useDeleteColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteColumnRoute>>, TError,{data: DeleteColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteColumnRoute>>,
        TError,
        {data: DeleteColumnRequest},
        TContext
      > => {

      const mutationOptions = getDeleteColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Cell Route
 */
export const updateCellRoute = (
    updateCellRequest: UpdateCellRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/update_cell`,
      updateCellRequest,options
    );
  }



export const getUpdateCellRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCellRoute>>, TError,{data: UpdateCellRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCellRoute>>, TError,{data: UpdateCellRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCellRoute>>, {data: UpdateCellRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateCellRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCellRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateCellRoute>>>
    export type UpdateCellRouteMutationBody = UpdateCellRequest
    export type UpdateCellRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Cell Route
 */
export const useUpdateCellRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCellRoute>>, TError,{data: UpdateCellRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCellRoute>>,
        TError,
        {data: UpdateCellRequest},
        TContext
      > => {

      const mutationOptions = getUpdateCellRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Column Metadata Route
 */
export const updateColumnMetadataRoute = (
    updateColumnMetadataRequest: UpdateColumnMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/update_column_metadata`,
      updateColumnMetadataRequest,options
    );
  }



export const getUpdateColumnMetadataRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, TError,{data: UpdateColumnMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, TError,{data: UpdateColumnMetadataRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, {data: UpdateColumnMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateColumnMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateColumnMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateColumnMetadataRoute>>>
    export type UpdateColumnMetadataRouteMutationBody = UpdateColumnMetadataRequest
    export type UpdateColumnMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Column Metadata Route
 */
export const useUpdateColumnMetadataRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateColumnMetadataRoute>>, TError,{data: UpdateColumnMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateColumnMetadataRoute>>,
        TError,
        {data: UpdateColumnMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateColumnMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Adds a new category to a categorical column.
 * @summary Add Column Category Route
 */
export const addColumnCategoryRoute = (
    addColumnCategoryRequest: AddColumnCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/add_column_category`,
      addColumnCategoryRequest,options
    );
  }



export const getAddColumnCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addColumnCategoryRoute>>, TError,{data: AddColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addColumnCategoryRoute>>, TError,{data: AddColumnCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addColumnCategoryRoute>>, {data: AddColumnCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  addColumnCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddColumnCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addColumnCategoryRoute>>>
    export type AddColumnCategoryRouteMutationBody = AddColumnCategoryRequest
    export type AddColumnCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Column Category Route
 */
export const useAddColumnCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addColumnCategoryRoute>>, TError,{data: AddColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addColumnCategoryRoute>>,
        TError,
        {data: AddColumnCategoryRequest},
        TContext
      > => {

      const mutationOptions = getAddColumnCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Renames a category in a categorical column. Updates all cells with the old category to the new category.
 * @summary Rename Column Category Route
 */
export const renameColumnCategoryRoute = (
    renameColumnCategoryRequest: RenameColumnCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/rename_column_category`,
      renameColumnCategoryRequest,options
    );
  }



export const getRenameColumnCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, TError,{data: RenameColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, TError,{data: RenameColumnCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, {data: RenameColumnCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameColumnCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameColumnCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameColumnCategoryRoute>>>
    export type RenameColumnCategoryRouteMutationBody = RenameColumnCategoryRequest
    export type RenameColumnCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Column Category Route
 */
export const useRenameColumnCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameColumnCategoryRoute>>, TError,{data: RenameColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameColumnCategoryRoute>>,
        TError,
        {data: RenameColumnCategoryRequest},
        TContext
      > => {

      const mutationOptions = getRenameColumnCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Change the color of a category in a categorical column.
 * @summary Change Category Color Route
 */
export const changeCategoryColorRoute = (
    changeCategoryColorRequest: ChangeCategoryColorRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/change_category_color`,
      changeCategoryColorRequest,options
    );
  }



export const getChangeCategoryColorRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeCategoryColorRoute>>, TError,{data: ChangeCategoryColorRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof changeCategoryColorRoute>>, TError,{data: ChangeCategoryColorRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeCategoryColorRoute>>, {data: ChangeCategoryColorRequest}> = (props) => {
          const {data} = props ?? {};

          return  changeCategoryColorRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeCategoryColorRouteMutationResult = NonNullable<Awaited<ReturnType<typeof changeCategoryColorRoute>>>
    export type ChangeCategoryColorRouteMutationBody = ChangeCategoryColorRequest
    export type ChangeCategoryColorRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Change Category Color Route
 */
export const useChangeCategoryColorRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeCategoryColorRoute>>, TError,{data: ChangeCategoryColorRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof changeCategoryColorRoute>>,
        TError,
        {data: ChangeCategoryColorRequest},
        TContext
      > => {

      const mutationOptions = getChangeCategoryColorRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a category from a categorical column. Updates all cells with the category to remove to None.
 * @summary Remove Column Category Route
 */
export const removeColumnCategoryRoute = (
    removeColumnCategoryRequest: RemoveColumnCategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/remove_column_category`,
      removeColumnCategoryRequest,options
    );
  }



export const getRemoveColumnCategoryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, TError,{data: RemoveColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, TError,{data: RemoveColumnCategoryRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, {data: RemoveColumnCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  removeColumnCategoryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveColumnCategoryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof removeColumnCategoryRoute>>>
    export type RemoveColumnCategoryRouteMutationBody = RemoveColumnCategoryRequest
    export type RemoveColumnCategoryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Remove Column Category Route
 */
export const useRemoveColumnCategoryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeColumnCategoryRoute>>, TError,{data: RemoveColumnCategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof removeColumnCategoryRoute>>,
        TError,
        {data: RemoveColumnCategoryRequest},
        TContext
      > => {

      const mutationOptions = getRemoveColumnCategoryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Move rows in a table.
 * @summary Move Rows Route
 */
export const moveRowsRoute = (
    moveRowsRequest: MoveRowsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/move_rows`,
      moveRowsRequest,options
    );
  }



export const getMoveRowsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveRowsRoute>>, TError,{data: MoveRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveRowsRoute>>, TError,{data: MoveRowsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveRowsRoute>>, {data: MoveRowsRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveRowsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveRowsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof moveRowsRoute>>>
    export type MoveRowsRouteMutationBody = MoveRowsRequest
    export type MoveRowsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Rows Route
 */
export const useMoveRowsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveRowsRoute>>, TError,{data: MoveRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveRowsRoute>>,
        TError,
        {data: MoveRowsRequest},
        TContext
      > => {

      const mutationOptions = getMoveRowsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Move a column in a table to a new position.
 * @summary Move Column Route
 */
export const moveColumnRoute = (
    moveColumnRequest: MoveColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/move_column`,
      moveColumnRequest,options
    );
  }



export const getMoveColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveColumnRoute>>, TError,{data: MoveColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof moveColumnRoute>>, TError,{data: MoveColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveColumnRoute>>, {data: MoveColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  moveColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof moveColumnRoute>>>
    export type MoveColumnRouteMutationBody = MoveColumnRequest
    export type MoveColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Move Column Route
 */
export const useMoveColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveColumnRoute>>, TError,{data: MoveColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof moveColumnRoute>>,
        TError,
        {data: MoveColumnRequest},
        TContext
      > => {

      const mutationOptions = getMoveColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update the width of a column in a table.
 * @summary Resize Column Route
 */
export const resizeColumnRoute = (
    resizeColumnRequest: ResizeColumnRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/tables/resize_column`,
      resizeColumnRequest,options
    );
  }



export const getResizeColumnRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resizeColumnRoute>>, TError,{data: ResizeColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof resizeColumnRoute>>, TError,{data: ResizeColumnRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resizeColumnRoute>>, {data: ResizeColumnRequest}> = (props) => {
          const {data} = props ?? {};

          return  resizeColumnRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResizeColumnRouteMutationResult = NonNullable<Awaited<ReturnType<typeof resizeColumnRoute>>>
    export type ResizeColumnRouteMutationBody = ResizeColumnRequest
    export type ResizeColumnRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Resize Column Route
 */
export const useResizeColumnRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resizeColumnRoute>>, TError,{data: ResizeColumnRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof resizeColumnRoute>>,
        TError,
        {data: ResizeColumnRequest},
        TContext
      > => {

      const mutationOptions = getResizeColumnRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List the columns in a table.
 * @summary List Table Columns Route
 */
export const listTableColumnsRoute = (
    tableId: TableId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedColumn[]>> => {
    
    return axios.default.get(
      `/tables/${tableId}/columns`,options
    );
  }


export const getListTableColumnsRouteQueryKey = (tableId: TableId,) => {
    return [`/tables/${tableId}/columns`] as const;
    }

    
export const getListTableColumnsRouteQueryOptions = <TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTableColumnsRouteQueryKey(tableId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTableColumnsRoute>>> = ({ signal }) => listTableColumnsRoute(tableId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tableId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTableColumnsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof listTableColumnsRoute>>>
export type ListTableColumnsRouteQueryError = AxiosError<HTTPValidationError>


export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listTableColumnsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listTableColumnsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Table Columns Route
 */

export function useListTableColumnsRoute<TData = Awaited<ReturnType<typeof listTableColumnsRoute>>, TError = AxiosError<HTTPValidationError>>(
 tableId: TableId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTableColumnsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getListTableColumnsRouteQueryOptions(tableId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List specified tables and their columns.
 * @summary List Tables And Columns Route
 */
export const listTablesAndColumnsRoute = (
    params: ListTablesAndColumnsRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MaterializedColumn[]>> => {
    
    return axios.default.post(
      `/tables/columns`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getListTablesAndColumnsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, TError,{params: ListTablesAndColumnsRouteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, TError,{params: ListTablesAndColumnsRouteParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, {params: ListTablesAndColumnsRouteParams}> = (props) => {
          const {params} = props ?? {};

          return  listTablesAndColumnsRoute(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ListTablesAndColumnsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>>
    
    export type ListTablesAndColumnsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary List Tables And Columns Route
 */
export const useListTablesAndColumnsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof listTablesAndColumnsRoute>>, TError,{params: ListTablesAndColumnsRouteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof listTablesAndColumnsRoute>>,
        TError,
        {params: ListTablesAndColumnsRouteParams},
        TContext
      > => {

      const mutationOptions = getListTablesAndColumnsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the rows that match the filters.
 * @summary Get Filtered Rows Route
 */
export const getFilteredRowsRoute = (
    getFilteredRowsRequest: GetFilteredRowsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RowId[]>> => {
    
    return axios.default.post(
      `/tables/get_filtered_rows`,
      getFilteredRowsRequest,options
    );
  }



export const getGetFilteredRowsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFilteredRowsRoute>>, TError,{data: GetFilteredRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof getFilteredRowsRoute>>, TError,{data: GetFilteredRowsRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getFilteredRowsRoute>>, {data: GetFilteredRowsRequest}> = (props) => {
          const {data} = props ?? {};

          return  getFilteredRowsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetFilteredRowsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof getFilteredRowsRoute>>>
    export type GetFilteredRowsRouteMutationBody = GetFilteredRowsRequest
    export type GetFilteredRowsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Get Filtered Rows Route
 */
export const useGetFilteredRowsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFilteredRowsRoute>>, TError,{data: GetFilteredRowsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof getFilteredRowsRoute>>,
        TError,
        {data: GetFilteredRowsRequest},
        TContext
      > => {

      const mutationOptions = getGetFilteredRowsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Preview the computed table.
 * @summary Preview Computed Table Route
 */
export const previewComputedTableRoute = (
    previewComputedTableRequest: PreviewComputedTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewComputedTableResponse>> => {
    
    return axios.default.post(
      `/tables/preview_computed_table`,
      previewComputedTableRequest,options
    );
  }



export const getPreviewComputedTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewComputedTableRoute>>, TError,{data: PreviewComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof previewComputedTableRoute>>, TError,{data: PreviewComputedTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewComputedTableRoute>>, {data: PreviewComputedTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  previewComputedTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PreviewComputedTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof previewComputedTableRoute>>>
    export type PreviewComputedTableRouteMutationBody = PreviewComputedTableRequest
    export type PreviewComputedTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Computed Table Route
 */
export const usePreviewComputedTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewComputedTableRoute>>, TError,{data: PreviewComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof previewComputedTableRoute>>,
        TError,
        {data: PreviewComputedTableRequest},
        TContext
      > => {

      const mutationOptions = getPreviewComputedTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a computed table from a parent table.
 * @summary Create Computed Table Route
 */
export const createComputedTableRoute = (
    createComputedTableRequest: CreateComputedTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableMetadata>> => {
    
    return axios.default.post(
      `/tables/create_computed_table`,
      createComputedTableRequest,options
    );
  }



export const getCreateComputedTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createComputedTableRoute>>, {data: CreateComputedTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createComputedTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateComputedTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createComputedTableRoute>>>
    export type CreateComputedTableRouteMutationBody = CreateComputedTableRequest
    export type CreateComputedTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Computed Table Route
 */
export const useCreateComputedTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createComputedTableRoute>>,
        TError,
        {data: CreateComputedTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateComputedTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Syncs a client execution of a message action.
 * @summary Sync Message Action Route
 */
export const syncMessageActionRoute = (
    syncUserMessageActionRequest: SyncUserMessageActionRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/sessions/sync_message_action`,
      syncUserMessageActionRequest,options
    );
  }



export const getSyncMessageActionRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncMessageActionRoute>>, TError,{data: SyncUserMessageActionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncMessageActionRoute>>, TError,{data: SyncUserMessageActionRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncMessageActionRoute>>, {data: SyncUserMessageActionRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncMessageActionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncMessageActionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncMessageActionRoute>>>
    export type SyncMessageActionRouteMutationBody = SyncUserMessageActionRequest
    export type SyncMessageActionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Message Action Route
 */
export const useSyncMessageActionRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncMessageActionRoute>>, TError,{data: SyncUserMessageActionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncMessageActionRoute>>,
        TError,
        {data: SyncUserMessageActionRequest},
        TContext
      > => {

      const mutationOptions = getSyncMessageActionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Syncs a client execution of an open thread action.
 * @summary Sync Open Thread Action Route
 */
export const syncOpenThreadActionRoute = (
    syncUserOpenThreadActionRequest: SyncUserOpenThreadActionRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/sessions/sync_open_thread_action`,
      syncUserOpenThreadActionRequest,options
    );
  }



export const getSyncOpenThreadActionRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncOpenThreadActionRoute>>, TError,{data: SyncUserOpenThreadActionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncOpenThreadActionRoute>>, TError,{data: SyncUserOpenThreadActionRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncOpenThreadActionRoute>>, {data: SyncUserOpenThreadActionRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncOpenThreadActionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncOpenThreadActionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncOpenThreadActionRoute>>>
    export type SyncOpenThreadActionRouteMutationBody = SyncUserOpenThreadActionRequest
    export type SyncOpenThreadActionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Open Thread Action Route
 */
export const useSyncOpenThreadActionRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncOpenThreadActionRoute>>, TError,{data: SyncUserOpenThreadActionRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncOpenThreadActionRoute>>,
        TError,
        {data: SyncUserOpenThreadActionRequest},
        TContext
      > => {

      const mutationOptions = getSyncOpenThreadActionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Chat
 */
export const dummyChat = (
    params: DummyChatParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ChatResponse>> => {
    
    return axios.default.post(
      `/dummy/chat`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDummyChatMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyChat>>, TError,{params: DummyChatParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyChat>>, TError,{params: DummyChatParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyChat>>, {params: DummyChatParams}> = (props) => {
          const {params} = props ?? {};

          return  dummyChat(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyChatMutationResult = NonNullable<Awaited<ReturnType<typeof dummyChat>>>
    
    export type DummyChatMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Dummy Chat
 */
export const useDummyChat = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyChat>>, TError,{params: DummyChatParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyChat>>,
        TError,
        {params: DummyChatParams},
        TContext
      > => {

      const mutationOptions = getDummyChatMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Workspace
 */
export const dummyWorkspace = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUpdateEvent>> => {
    
    return axios.default.post(
      `/dummy/workspace`,undefined,options
    );
  }



export const getDummyWorkspaceMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspace>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspace>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyWorkspace>>, void> = () => {
          

          return  dummyWorkspace(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof dummyWorkspace>>>
    
    export type DummyWorkspaceMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Workspace
 */
export const useDummyWorkspace = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyWorkspace>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyWorkspace>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Tables
 */
export const dummyTables = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableResponse>> => {
    
    return axios.default.post(
      `/dummy/tables`,undefined,options
    );
  }



export const getDummyTablesMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTables>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyTables>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyTables>>, void> = () => {
          

          return  dummyTables(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyTablesMutationResult = NonNullable<Awaited<ReturnType<typeof dummyTables>>>
    
    export type DummyTablesMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Tables
 */
export const useDummyTables = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTables>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyTables>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyTablesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Sessions
 */
export const dummySessions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionEvent>> => {
    
    return axios.default.post(
      `/dummy/sessions`,undefined,options
    );
  }



export const getDummySessionsMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummySessions>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummySessions>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummySessions>>, void> = () => {
          

          return  dummySessions(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummySessionsMutationResult = NonNullable<Awaited<ReturnType<typeof dummySessions>>>
    
    export type DummySessionsMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Sessions
 */
export const useDummySessions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummySessions>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummySessions>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummySessionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Message With Agent Type
 */
export const dummyMessageWithAgentType = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MessageWithMetadata>> => {
    
    return axios.default.post(
      `/dummy/message-with-agent-type`,undefined,options
    );
  }



export const getDummyMessageWithAgentTypeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, void> = () => {
          

          return  dummyMessageWithAgentType(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyMessageWithAgentTypeMutationResult = NonNullable<Awaited<ReturnType<typeof dummyMessageWithAgentType>>>
    
    export type DummyMessageWithAgentTypeMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Message With Agent Type
 */
export const useDummyMessageWithAgentType = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyMessageWithAgentType>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyMessageWithAgentTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Ping
 */
export const ping = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/ping`,options
    );
  }


export const getPingQueryKey = () => {
    return [`/ping`] as const;
    }

    
export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: QueryKey }
}

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = AxiosError<unknown>


export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Ping
 */

export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




