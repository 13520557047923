import type { TableCellProps } from "@/components/TableComponent/cellTypes";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableContext } from "@/contexts/TableContext";
import type { NumberCellValue, NumberColumnMetadata } from "@api/schemas";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const NumberCell = observer(
	({
		rowId,
		columnId,
		cellValue,
		isProxy,
	}: TableCellProps<NumberColumnMetadata>) => {
		const [open, setOpen] = useState(false);
		const tableContext = useTableContext();

		let castValue: NumberCellValue | null;
		if (cellValue && cellValue.cell_value_type !== "number") {
			castValue = null;
		} else {
			castValue = cellValue;
		}
		const currentValue = castValue?.cell_value;

		const [newValue, setNewValue] = useState<string>(
			currentValue?.toString() ?? "",
		);
		useEffect(() => {
			setNewValue(currentValue?.toString() ?? "");
		}, [currentValue]);

		const onSubmit = () => {
			const newNumber = Number.parseFloat(newValue);
			if (newNumber !== currentValue && !Number.isNaN(newNumber)) {
				tableContext.updateCell({
					rowId,
					columnId,
					value: {
						cell_value: newNumber,
						cell_value_type: "number",
					},
				});
			}
		};

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
					if (!newOpen) {
						onSubmit();
					}
				}}
			>
				<PopoverTrigger
					className={clsx(
						"flex h-full w-full min-w-0 flex-col items-end justify-start truncate p-1 text-right text-neutral-800 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
					disabled={isProxy || !tableContext.editable}
				>
					{newValue || currentValue}
				</PopoverTrigger>
				<PopoverContent align="start" className="overflow-hidden p-0">
					<input
						value={newValue}
						onChange={(e) => {
							setNewValue(e.target.value);
						}}
						className="w-full p-2 text-sm outline-none focus:outline-none"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								setOpen(false);
								onSubmit();
							}
						}}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
