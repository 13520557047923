import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { TableView } from "@/components/TableComponent";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/AppContext";
import { TableProvider, useTableContext } from "@/contexts/TableContext";
import { formatRelativeDate } from "@/lib/formatting";
import { useGetTableLatestVersion } from "@api/fastAPI";
import type { TableId, TableMetadata } from "@api/schemas";
import { RectangleDashed } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const LoadingState = observer(() => {
	return (
		<div className="relative flex h-full min-h-0 w-full flex-col">
			<Helmet>
				<title>Table - Village</title>
			</Helmet>
		</div>
	);
});

// Separate headers for loading and loaded states because the loading state
// uses appContext to show the table name, but the loaded state needs
// the tableContext to show additional info and toggles
export const LoadingTableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableMetadata }) => {
		const appContext = useAppContext();
		return (
			<div className="flex h-14 w-full shrink-0 items-center px-2">
				{!appContext.showSidebar && <ShowSidebarButton />}
				<div className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-none focus:ring-1">
					{tableMetadata.file_name}
				</div>
			</div>
		);
	},
);

export const LoadedTableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableMetadata }) => {
		const appContext = useAppContext();
		const tableContext = useTableContext();

		const [newName, setNewName] = useState(tableMetadata.file_name);
		useEffect(() => {
			setNewName(tableMetadata.file_name);
		}, [tableMetadata.file_name]);

		const onSubmit = () => {
			if (newName === tableMetadata.file_name) {
				return;
			}
			if (newName.trim() === "") {
				toast.error("Table name cannot be empty");
				setNewName(tableMetadata.file_name);
				return;
			}
			appContext.renameTable({
				tableId: tableMetadata.table_id,
				tableName: newName,
			});
		};

		return (
			<>
				<div className="flex h-14 w-full shrink-0 items-center px-2">
					{!appContext.showSidebar && <ShowSidebarButton />}
					<input
						value={newName}
						className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-none focus:ring-1"
						placeholder="Untitled table"
						onChange={(e) => {
							setNewName(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSubmit();
							}
						}}
						onBlur={onSubmit}
					/>
				</div>

				<div className="flex shrink-0 items-center border-b px-4 pb-2 text-neutral-500 text-sm">
					Updated{" "}
					{formatRelativeDate(
						tableContext.table.root.latestTransaction
							.table_transaction_created_at,
					)}
					{IS_DEV && (
						<div className="ml-2 flex max-w-max items-center gap-2 rounded-md border bg-neutral-50 p-1">
							<Switch
								checked={tableContext.devMode}
								onCheckedChange={(checked) => {
									runInAction(() => {
										tableContext.devMode = checked;
									});
								}}
							/>{" "}
							<Label>Dev mode</Label>
						</div>
					)}
				</div>
			</>
		);
	},
);

export const Table = observer(() => {
	const { activeTableId } = useParams();
	const appContext = useAppContext();

	const { data: table, error } = useGetTableLatestVersion(
		activeTableId as TableId,
		{
			query: {
				queryKey: ["table", activeTableId],
				// disable stale time to always fetch the latest data
				gcTime: 0,
			},
		},
	);

	if (!activeTableId) {
		return <Navigate to="/tables" />;
	}

	if (!appContext.workspaceHasLoaded) {
		return <LoadingState />;
	}

	const tableMetadata = appContext.getTableById(activeTableId as TableId);
	if (!tableMetadata || error) {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center">
				<RectangleDashed className="h-24 w-24 text-neutral-300" />
				<h1 className="mt-4 font-semibold text-xl">Table not found</h1>
				<p className="mt-2 text-center text-neutral-500">
					We couldn't find the table you're looking for.
					<br />
					If you think this is a mistake, please contact us.
				</p>

				<NavLink to="/tables" className="mt-4">
					<Button variant="outline">View all tables</Button>
				</NavLink>
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col">
			{table ? (
				<TableProvider
					tableId={activeTableId as TableId}
					tableData={table.data}
					key={activeTableId}
					editable
				>
					<LoadedTableHeader tableMetadata={tableMetadata} />
					<TableView />
				</TableProvider>
			) : (
				<>
					<LoadingTableHeader tableMetadata={tableMetadata} />
					<LoadingState />
				</>
			)}
		</div>
	);
});
