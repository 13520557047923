import { BooleanCell } from "@/components/TableComponent/Cells/BooleanCell";
import { CategoryCell } from "@/components/TableComponent/Cells/CategoryCell";
import { DateCell } from "@/components/TableComponent/Cells/DateCell";
import { DatetimeCell } from "@/components/TableComponent/Cells/DatetimeCell";
import { DocumentCell } from "@/components/TableComponent/Cells/DocumentCell";
import { NumberCell } from "@/components/TableComponent/Cells/NumberCell";
import { TextCell } from "@/components/TableComponent/Cells/TextCell";
import type { TableCellProps } from "@/components/TableComponent/cellTypes";
import type {
	ColumnMetadata,
	ProxyColumnMetadata,
	ProxyGroupColumnMetadata,
} from "@api/schemas";

export function BaseCellRenderer<
	T extends Exclude<
		ColumnMetadata,
		ProxyColumnMetadata | ProxyGroupColumnMetadata
	>,
>(props: TableCellProps<T>) {
	const { rowId, columnId, cellValue, columnMetadata, isProxy } = props;

	switch (columnMetadata.column_type) {
		case "boolean":
			return (
				<BooleanCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		case "category":
			return (
				<CategoryCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		case "date":
			return (
				<DateCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		case "datetime":
			return (
				<DatetimeCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		case "document":
			return (
				<DocumentCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		case "number":
			return (
				<NumberCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		case "text":
			return (
				<TextCell
					rowId={rowId}
					columnId={columnId}
					cellValue={cellValue}
					columnMetadata={columnMetadata}
					isProxy={isProxy}
				/>
			);
		default: {
			const exhaustiveCheck: never = columnMetadata;
			return exhaustiveCheck;
		}
	}
}
