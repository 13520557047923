import type { TableCellProps } from "@/components/TableComponent/cellTypes";
import { useTableContext } from "@/contexts/TableContext";
import type { ProxyGroupColumnMetadata } from "@api/schemas";
import { observer } from "mobx-react-lite";

export const ProxiedGroupCell = observer(
	({ rowId, columnId }: TableCellProps<ProxyGroupColumnMetadata>) => {
		const tableContext = useTableContext();

		const cellValue = tableContext.getCellValue(rowId, columnId);

		if (!cellValue) {
			return null;
		}
		if (cellValue.cell_value_type !== "proxy_group") {
			return null;
		}

		return (
			<div className="flex p-1 text-neutral-500 text-sm">
				{cellValue.cell_value.length} elements
			</div>
		);
	},
);
